import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table';
import { ExternalLinkIcon } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

import { Button } from '@/components/ui/button';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { fetchWithSupabase } from '@/lib/api/fetch';
import { communityInvitationsQueryKey } from '@/lib/api/queryKeys';
import { CommunityInvitationsResponse } from '@/lib/api/types';
import {
  GET_COMMUNITY_INVITATIONS_URL,
  GET_INVITATION_INFO,
  INVITE_USERS_TO_COMMUNITY_URL,
} from '@/lib/api/url';

import { Badge } from '../ui/badge';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import { Loader } from '../ui/loader';
import { Invitation } from './types/invitation';

type Props = {
  communityId: string;
};

export const CommunityInvitationsList = ({ communityId }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = useState({});
  const { data, isFetching, isError } = useQuery({
    queryKey: [communityInvitationsQueryKey, communityId],
    initialData: [],
    queryFn: async () => {
      const { invitations } = await fetchWithSupabase<CommunityInvitationsResponse>(
        GET_COMMUNITY_INVITATIONS_URL(communityId),
        {
          method: 'GET',
        },
      );

      return invitations.filter((i) => i.status !== 'ACCEPTED');
    },
  });

  const columns: ColumnDef<Invitation>[] = [
    {
      accessorKey: 'invitee_email',
      header: t('community.invitations.email'),
      cell: ({ row }) => (
        <div className="min-w-[180px] truncate">{row.getValue('invitee_email')}</div>
      ),
    },
    {
      accessorKey: 'status',
      header: 'Status',
      cell: ({ row }) => (
        <div className="min-w-[100px]">
          <Badge variant="default">
            {t(`community.invitations.status.${row.getValue('status')}`)}
          </Badge>
        </div>
      ),
    },
    {
      accessorKey: 'invite_code',
      header: 'URL',
      cell: ({ row }) => (
        <div className="min-w-[60px]">
          <a
            href={`${window.location.origin}/join-community/${row.getValue('invite_code')}`}
            target="_blank"
            rel="noreferrer"
            className="inline-block"
          >
            <Button variant="secondary" className="h-8 w-8 p-1">
              <ExternalLinkIcon className="h-4 w-4" />
            </Button>
          </a>
        </div>
      ),
    },
    {
      id: 'actions',
      enableHiding: false,
      cell: ({ row }) => {
        const status = row.getValue('status');

        if (status === 'SENT') {
          const onResendClick = async () => {
            const resendPromise = fetchWithSupabase(
              INVITE_USERS_TO_COMMUNITY_URL(communityId),
              {
                method: 'POST',
                body: JSON.stringify({
                  emails: [row.getValue('invitee_email')],
                }),
              },
            );

            toast.promise(resendPromise, {
              loading: t('community.invitations.resend.loading'),
              success: t('community.invitations.resend.success'),
              error: t('community.invitations.resend.error'),
            });
          };

          const onRevokeClick = async () => {
            const revokePromise = fetchWithSupabase(
              GET_INVITATION_INFO(row.getValue('invite_code')),
              {
                method: 'DELETE',
              },
            ).then(() => {
              queryClient.invalidateQueries({
                queryKey: [communityInvitationsQueryKey, communityId],
              });
            });

            toast.promise(revokePromise, {
              loading: t('community.invitations.revoke.loading'),
              success: t('community.invitations.revoke.success'),
              error: t('community.invitations.revoke.error'),
            });
          };

          return (
            <div className="text-right">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="ghost" className="h-8 w-8 p-0">
                    <DotsHorizontalIcon className="h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuItem onClick={onResendClick}>
                    {t('community.invitations.resend.cta')}
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={onRevokeClick}>
                    {t('community.invitations.revoke.cta')}
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          );
        }

        return null;
      },
    },
  ];

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  if (isFetching) {
    return <Loader />;
  }

  if (isError) {
    return null;
  }

  return (
    <div className="w-full">
      <div className="rounded-md border overflow-x-auto">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} className="whitespace-nowrap">
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} className="whitespace-nowrap">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  {t('community.invitations.empty')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex justify-end gap-2 py-4 px-2">
        <Button
          variant="outline"
          size="sm"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
          className="flex-1 md:flex-none"
        >
          {t('community.invitations.previous')}
        </Button>
        <Button
          variant="outline"
          size="sm"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
          className="flex-1 md:flex-none"
        >
          {t('community.invitations.next')}
        </Button>
      </div>
    </div>
  );
};
