import { useQuery } from '@tanstack/react-query';

import { useAuth } from '@/components/auth/AuthProvider';
import { useUserCommunity } from '@/components/community/hooks/useUserCommunity';
import { fetchWithSupabase } from '@/lib/api/fetch';
import { ContractsResponse } from '@/lib/api/types';
import { GET_CONTRACT_INFO } from '@/lib/api/url';

export const useUserContracts = () => {
  const { session } = useAuth();
  const { member } = useUserCommunity();
  const { data: contracts, isLoading } = useQuery({
    queryKey: ['contracts'],
    queryFn: async () => {
      const res = await fetchWithSupabase<ContractsResponse>(
        `${GET_CONTRACT_INFO}?email=${encodeURIComponent(session?.user.email || '')}`,
        {
          method: 'GET',
        },
      );
      return res.contracts_info;
    },
    initialData: [],
    enabled: !!member,
  });

  const hasUnsignedContracts = contracts.some((contract) => !contract.is_signed);

  return {
    contracts,
    isLoading,
    hasUnsignedContracts,
  };
};
