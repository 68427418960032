import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Loader } from '@/components/ui/loader';
import { supabase } from '@/lib/supabase/config';

export const ResetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const extractTokenFromHash = () => {
    if (location.hash) {
      const hashParams = new URLSearchParams(location.hash.slice(1)); // Remove the `#` and parse
      return hashParams.get('access_token'); // Get the actual `access_token`
    }
    return null;
  };

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      password: '',
    },
  });

  const handleResetPassword = async ({ password }: { password: string }) => {
    const accessToken = extractTokenFromHash();
    try {
      if (!accessToken) {
        throw new Error(t('resetPassword.missingToken'));
      }

      const { error: sessionError } = await supabase.auth.setSession({
        access_token: accessToken,
        refresh_token: accessToken,
      });

      if (sessionError) {
        throw new Error(sessionError.message);
      }

      // Update the password
      const { error } = await supabase.auth.updateUser({
        password,
      });

      if (error) {
        toast.error(t('resetPassword.error'), {
          description: error.message,
        });
      } else {
        toast.success(t('resetPassword.success'));
        navigate('/login?is_password_reset=true');
      }
    } catch (err) {
      toast.error(t('resetPassword.error'), {
        description: (err as Error).message,
      });
    }
  };

  return (
    <div className="container relative h-screen flex-col items-center justify-center grid lg:max-w-[840px] mx-auto lg:grid-cols-2 lg:px-0 overflow-auto">
      <div className="relative hidden bg-background h-full p-10 lg:flex items-center justify-center flex-col">
        <div>
          <img alt="logo" src="/energico.png" className="h-16" />
          <blockquote className="space-y-2">
            <p className="text-md">{t('resetPassword.punchline')}</p>
          </blockquote>
        </div>
      </div>
      <div className="lg:p-8">
        <div className="mx-auto flex w-full flex-col justify-center space-y-6 max-w-[350px]">
          <div className="flex flex-col space-y-2 text-center">
            <h1 className="text-2xl font-semibold tracking-tight">
              {t('resetPassword.title')}
            </h1>
            <p className="text-sm text-muted-foreground">{t('resetPassword.subtitle')}</p>
          </div>
          <form onSubmit={handleSubmit(handleResetPassword)}>
            <div className="grid gap-2">
              <div className="grid gap-1">
                <Label className="sr-only" htmlFor="password">
                  {t('resetPassword.password')}
                </Label>
                <Input
                  id="password"
                  placeholder={t('resetPassword.passwordPlaceholder')}
                  type="password"
                  autoCapitalize="none"
                  autoComplete="new-password"
                  autoCorrect="off"
                  {...register('password', {
                    required: t('resetPassword.passwordRequired'),
                  })}
                />
              </div>
              <Button disabled={isSubmitting}>
                {isSubmitting && <Loader className="mr-1" />}
                {t('resetPassword.cta')}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
