import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { toast } from 'sonner';

import { useAuth } from '@/components/auth/AuthProvider';
import { Button } from '@/components/ui/button';
import { Title } from '@/components/ui/custom/title';
import { Loader } from '@/components/ui/loader';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { fetchWithSupabase } from '@/lib/api/fetch';
import { allCommunitiesQueryKey, allUsersQueryKey } from '@/lib/api/queryKeys';
import {
  AllCommunitiesResponse,
  AllUsersResponse,
  ApproveCommunityResponse,
  ImpersonateUserResponse,
} from '@/lib/api/types';
import {
  APPROVE_COMMUNITY_URL,
  GET_ALL_COMMUNITIES_URL,
  GET_ALL_USERS_URL,
  IMPERSONATE_USER_URL,
} from '@/lib/api/url';
import { removeTrailingCharacter } from '@/lib/utils';

export const SuperAdminPanel = () => {
  const { session } = useAuth();
  const [isImpersonating, setIsImpersonating] = useState(false);

  const { data: users, isLoading: usersLoading } = useQuery({
    queryKey: [allUsersQueryKey],
    queryFn: async () => {
      return await fetchWithSupabase<AllUsersResponse>(GET_ALL_USERS_URL, {
        method: 'GET',
      });
    },
    enabled: !!session,
  });

  const { data: communities, isLoading: communitiesLoading } = useQuery({
    queryKey: [allCommunitiesQueryKey],
    queryFn: async () => {
      return await fetchWithSupabase<AllCommunitiesResponse>(GET_ALL_COMMUNITIES_URL, {
        method: 'GET',
      });
    },
    enabled: !!session,
  });

  const impersonate = async (userId: string) => {
    setIsImpersonating(true);
    try {
      const {
        success,
        data: { link: _link, superadmin_link: _superadmin_link, email },
      } = await fetchWithSupabase<ImpersonateUserResponse>(IMPERSONATE_USER_URL(userId), {
        method: 'GET',
      });

      const [link, superadmin_link] = [_link, _superadmin_link].map((url) => {
        const urlObject = new URL(url);
        urlObject.searchParams.set(
          'redirect_to',
          removeTrailingCharacter(window.location.href),
        );
        return urlObject.toString();
      });

      if (!success) {
        throw new Error('An error occurred during impersonation.');
      }

      localStorage.setItem(
        'impersonate_data',
        JSON.stringify({ link, superadmin_link, email }),
      );
      window.location.href = link;
    } catch (error) {
      toast.error((error as Error).message ?? 'An error occurred during impersonation.');
    } finally {
      setIsImpersonating(false);
    }
  };

  const queryClient = useQueryClient();

  const handleApproveCommunity = async (communityId: string) => {
    try {
      // Send a request to your backend to approve the community
      const response = await fetchWithSupabase<ApproveCommunityResponse>(
        APPROVE_COMMUNITY_URL(communityId),
        {
          method: 'POST', // Or PUT, depending on your API design
        },
      );

      if (!response.success) {
        throw new Error(
          `Failed to approve community ${communityId}: ${JSON.stringify(response)}`,
        );
      }

      toast.success('Community approved successfully!');

      // Refetch the communities to update the list
      queryClient.invalidateQueries({ queryKey: [allCommunitiesQueryKey] });
    } catch (error) {
      toast.error((error as Error).message || 'Failed to approve community.');
    }
  };

  return usersLoading || communitiesLoading ? (
    <Loader />
  ) : (
    <div className="-mt-6 -mr-6 pt-6 max-h-screen overflow-auto">
      <Title title="Superadmin Dashboard" />
      <div className="grid grid-cols-2 my-4">
        <p>Currently logged in as</p>
        <p className="font-semibold">{session?.user?.email}</p>
      </div>
      <div className="grid grid-cols-1 gap-4 mt-4">
        {/* Split into two columns */}
        <div>
          <Title title="Users" />
          <Table>
            {/* Use a table to display users */}
            <TableHeader>
              <TableRow>
                <TableHead>ID</TableHead>
                <TableHead>Name</TableHead>
                <TableHead>Email</TableHead>
                <TableHead>Phone</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {users?.users?.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.id}</TableCell>
                  <TableCell>
                    {user.first_name} <strong>{user.last_name}</strong>
                  </TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.phone_number}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => impersonate(user.id)}
                      disabled={isImpersonating}
                    >
                      Impersonate
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <div>
          <Title title="CERs" />
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>ID</TableHead>
                <TableHead>Name</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {communities?.communities?.map((community) => (
                <TableRow key={community.id}>
                  <TableCell>{community.id}</TableCell>
                  <TableCell>{community.name}</TableCell>
                  <TableCell>
                    {community.is_activated ? (
                      <p>Approved</p>
                    ) : (
                      <Button onClick={() => handleApproveCommunity(community.id)}>
                        Approve
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
};
