import { Popover, PopoverContent, PopoverTrigger } from '@radix-ui/react-popover';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { enUS, it } from 'date-fns/locale';
import { CalendarIcon } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { fetchWithSupabase } from '@/lib/api/fetch';
import { userCommunitiesQueryKey } from '@/lib/api/queryKeys';
import { UPDATE_COMMUNITY_URL } from '@/lib/api/url';
import { cn } from '@/lib/utils';

import { Calendar } from '../ui/calendar';
import { Checkbox } from '../ui/checkbox';
import { Loader } from '../ui/loader';
import { useUserCommunity } from './hooks/useUserCommunity';
import { Community } from './types/community';

type CommunityFormProps = {
  community: Community & { is_admin: boolean };
};

export const CommunityForm = ({ community }: CommunityFormProps) => {
  const { t, i18n } = useTranslation();
  const { member } = useUserCommunity();
  const queryClient = useQueryClient();
  const form = useForm({
    mode: 'onSubmit',
    defaultValues: {
      name: community.name ?? undefined,
      activation_date: community.activation_date ?? undefined,
      pec_email_address: community.pec_email_address ?? undefined,
      iban: community.iban ?? undefined,
      use_dedicated_withdrawal: community.use_dedicated_withdrawal ?? undefined,
    },
  });

  const { mutate, isPending } = useMutation({
    mutationKey: ['updateCommunity', community.id],
    mutationFn: async (values: Partial<Community>) => {
      return await fetchWithSupabase(UPDATE_COMMUNITY_URL(community.id), {
        method: 'PUT',
        body: JSON.stringify(values),
      });
    },
    onError: () => toast.error('Failed to update community.'),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [userCommunitiesQueryKey],
      });
      toast.success(t('community.edit.success'));
    },
  });

  return (
    <div className="max-w-[500px]">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit((values) => mutate(values))}
          className="flex flex-col gap-4"
        >
          <FormField
            name="name"
            control={form.control}
            render={({ field }) => (
              <FormItem className="mb-4">
                <Label>{t('community.edit.name')}</Label>
                <FormControl>
                  <Input
                    placeholder={t('community.edit.namePlaceholder')}
                    {...form.register('name', { required: true })}
                    {...field}
                  />
                </FormControl>
                <FormDescription>{t('community.edit.nameDescription')}</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="activation_date"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <Label>{t('community.edit.activationDate')}</Label>
                <Popover>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button
                        disabled={!community.is_admin}
                        variant={'outline'}
                        className={cn(
                          'pl-3 text-left font-normal',
                          !field.value && 'text-muted-foreground',
                        )}
                      >
                        {field.value ? (
                          format(field.value, 'PPP', {
                            locale: it,
                          })
                        ) : (
                          <span>{t('misc.selectDate')}</span>
                        )}
                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent
                    className="w-auto p-0 bg-white rounded-md border"
                    align="start"
                  >
                    <Calendar
                      mode="single"
                      locale={i18n.language === 'it' ? it : enUS}
                      selected={field.value}
                      onSelect={field.onChange}
                      disabled={(date) =>
                        date > new Date() || date < new Date('1900-01-01')
                      }
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name="pec_email_address"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <Label>{t('community.edit.pecEmailAddress')}</Label>
                <FormControl>
                  <Input
                    {...form.register('pec_email_address', { required: true })}
                    {...field}
                    placeholder={t('community.edit.pecEmailAddressPlaceholder')}
                  />
                </FormControl>
                <FormDescription>
                  {t('community.edit.pecEmailAddressDescription')}
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name="iban"
            control={form.control}
            render={({ field }) => (
              <FormItem className="mb-4">
                <Label>{t('community.edit.iban')}</Label>
                <FormControl>
                  <Input
                    placeholder={t('community.edit.ibanPlaceholder')}
                    {...form.register('iban', { required: true })}
                    {...field}
                  />
                </FormControl>
                <FormDescription>{t('community.edit.ibanDescription')}</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name="use_dedicated_withdrawal"
            control={form.control}
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
                <FormControl>
                  <Checkbox
                    disabled={!community.is_admin}
                    checked={Boolean(field.value)}
                    onCheckedChange={(checked) => {
                      form.setValue('use_dedicated_withdrawal', Boolean(checked));
                    }}
                  />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel>
                    {t('registration.organisationDetails.isAssociateFirm')}
                  </FormLabel>
                </div>
              </FormItem>
            )}
          />
          {member?.is_admin && (
            <Button
              className="self-start mt-3"
              type="submit"
              disabled={!community.is_admin || isPending}
            >
              {isPending && <Loader className="mr-1" />}
              {t('community.edit.save')}
            </Button>
          )}
        </form>
      </Form>
    </div>
  );
};
