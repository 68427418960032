import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

import { useUpdateMember } from '@/components/community/hooks/useUpdateMember';
import { Member } from '@/components/community/types/member';
import { sanitizeMemberFormData } from '@/components/community/utils/member';

export const useMemberForm = (member: Member, onSuccess?: () => void) => {
  const form = useForm({
    mode: 'onSubmit',
    defaultValues: sanitizeMemberFormData(member),
  });
  const { t } = useTranslation();

  const { mutate, isPending } = useUpdateMember({
    onSuccess: (memberUpdated) => {
      onSuccess?.();
      form.reset(sanitizeMemberFormData(memberUpdated));
      toast.success(t('community.members.form.success'));
    },
    onError: () => {
      toast.error(t('community.members.form.error'));
    },
  });

  return {
    ...form,
    isPending,
    onSubmit: form.handleSubmit(async (data) => {
      await mutate(data);
    }),
  };
};
