import { useTranslation } from 'react-i18next';

import { LanguageSwitcher } from '@/components/common/LanguageSwitcher';
import { Title } from '@/components/ui/custom/title';
import { Label } from '@/components/ui/label';

export const SettingsPage = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Title title={t('settings.title')} subTitle={t('settings.subtitle')} />
      <div className="max-w-[300px] mt-5 flex flex-col gap-3">
        <Label>{t('admin.language')}</Label>
        <LanguageSwitcher />
      </div>
    </div>
  );
};
