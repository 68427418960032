import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useUserCommunity } from '../community/hooks/useUserCommunity';
import { LocationInformationForm } from '../member/LocationInformationForm';
import { Title } from '../ui/custom/title';

type Props = {
  mode?: 'registration' | 'profile';
};

export const RegistrationLocationInformation = ({ mode = 'registration' }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { member, isLoading } = useUserCommunity();

  if (!member || isLoading) {
    return null;
  }

  return (
    <LocationInformationForm
      member={member}
      title={<Title title={t('registration.locationInformation.title')} />}
      submitText={
        mode === 'registration'
          ? t('registration.form.next')
          : t('registration.form.save')
      }
      onSuccess={
        mode === 'registration' ? () => navigate('../financial-information') : undefined
      }
      cancel={{
        text: t('registration.form.back'),
        onClick: () => navigate('../energy-information'),
      }}
    />
  );
};
