import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

import { fetchWithSupabase } from '@/lib/api/fetch';
import { communityMembersQueryKey, userCommunitiesQueryKey } from '@/lib/api/queryKeys';
import { JoinInvitationResponse } from '@/lib/api/types';
import { JOIN_COMMUNITY } from '@/lib/api/url';

import { LogoutButton } from '../auth/LogoutButton';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { CommunityCreationModal } from './CommunityCreationModal';

export const CommunityEmpty = () => {
  const [isCommunityCreationModalOpen, setIsCommunityCreationModalOpen] = useState(false);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { register, handleSubmit } = useForm({
    defaultValues: {
      inviteCode: '',
    },
  });

  const { mutate } = useMutation({
    mutationKey: ['join-community'],
    mutationFn: async (inviteCode: string) => {
      return await fetchWithSupabase<JoinInvitationResponse>(JOIN_COMMUNITY(inviteCode), {
        method: 'POST',
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [communityMembersQueryKey] });
      queryClient.invalidateQueries({ queryKey: [userCommunitiesQueryKey] });
      toast.success(t('community.invitations.join.success'));
    },
  });

  return (
    <>
      <div className="flex h-screen w-full flex-col items-center justify-center ">
        <div className="flex flex-col gap-4 items-center text-center">
          <img alt="logo" src="/energico.png" className="h-16" />
        </div>
        <form
          className="mt-8 flex flex-col gap-4"
          onSubmit={handleSubmit(async (values) => await mutate(values.inviteCode))}
        >
          <Input
            {...register('inviteCode', { required: true })}
            placeholder={t('community.inviteCode')}
          />
          <Button type="submit">{t('community.joinCta')}</Button>
        </form>
        <div className="mt-8 flex flex-col gap-4">
          <Button variant="link" onClick={() => setIsCommunityCreationModalOpen(true)}>
            {t('community.createCta')}
          </Button>
          <LogoutButton />
        </div>
      </div>
      <CommunityCreationModal
        isOpen={isCommunityCreationModalOpen}
        onOpenChange={setIsCommunityCreationModalOpen}
      />
    </>
  );
};
