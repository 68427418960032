import { ResponsiveLine } from '@nivo/line';
import { useEffect, useMemo, useState } from 'react';

const useWindowSize = () => {
  const [width, setWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 0,
  );

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return width;
};

export const Graph = () => {
  const width = useWindowSize();
  const isMobile = width < 768; // md breakpoint

  const dataSet = useMemo(
    () => [
      {
        id: isMobile ? 'Sola.' : 'Solare',
        color: 'hsl(45, 70%, 50%)',
        data: [
          { x: '2009', y: 370 },
          { x: '2010', y: 250 },
          { x: '2011', y: 160 },
          { x: '2012', y: 120 },
          { x: '2013', y: 100 },
          { x: '2014', y: 80 },
          { x: '2015', y: 100 },
          { x: '2016', y: 70 },
          { x: '2017', y: 60 },
        ],
      },
      {
        id: isMobile ? 'Eoli.' : 'Eolico',
        color: 'hsl(120, 70%, 50%)',
        data: [
          { x: '2009', y: 130 },
          { x: '2010', y: 120 },
          { x: '2011', y: 70 },
          { x: '2012', y: 70 },
          { x: '2013', y: 70 },
          { x: '2014', y: 65 },
          { x: '2015', y: 65 },
          { x: '2016', y: 60 },
          { x: '2017', y: 50 },
        ],
      },
      {
        id: isMobile ? 'Carb.' : 'Carbone',
        color: 'hsl(0, 70%, 50%)',
        data: [
          { x: '2009', y: 105 },
          { x: '2010', y: 105 },
          { x: '2011', y: 105 },
          { x: '2012', y: 100 },
          { x: '2013', y: 102 },
          { x: '2014', y: 102 },
          { x: '2015', y: 102 },
          { x: '2016', y: 100 },
          { x: '2017', y: 100 },
        ],
      },
      {
        id: 'Gas',
        color: 'hsl(220, 70%, 50%)',
        data: [
          { x: '2009', y: 90 },
          { x: '2010', y: 90 },
          { x: '2011', y: 90 },
          { x: '2012', y: 85 },
          { x: '2013', y: 85 },
          { x: '2014', y: 85 },
          { x: '2015', y: 80 },
          { x: '2016', y: 75 },
          { x: '2017', y: 65 },
        ],
      },
      {
        id: isMobile ? 'Nucl.' : 'Nucleare',
        color: 'hsl(300, 70%, 50%)',
        data: [
          { x: '2009', y: 120 },
          { x: '2010', y: 100 },
          { x: '2011', y: 100 },
          { x: '2012', y: 100 },
          { x: '2013', y: 105 },
          { x: '2014', y: 110 },
          { x: '2015', y: 115 },
          { x: '2016', y: 120 },
          { x: '2017', y: 140 },
        ],
      },
    ],
    [],
  );

  const margin = isMobile
    ? { top: 50, right: 20, bottom: 120, left: 60 }
    : { top: 50, right: 110, bottom: 50, left: 60 };

  const legends = [
    Object.freeze({
      anchor: isMobile ? 'bottom' : 'bottom-right',
      direction: isMobile ? 'row' : 'column',
      justify: false,
      translateX: isMobile ? 0 : 100,
      translateY: isMobile ? 80 : 0,
      itemDirection: 'left-to-right',
      itemWidth: isMobile ? 50 : 80,
      itemHeight: 20,
      itemOpacity: 0.75,
      symbolSize: 12,
      symbolShape: 'circle',
      symbolBorderColor: 'rgba(0, 0, 0, .5)',
      effects: [
        Object.freeze({
          on: 'hover',
          style: {
            itemBackground: 'rgba(0, 0, 0, .03)',
            itemOpacity: 1,
          },
        }),
      ],
      itemsSpacing: isMobile ? 0 : 10,
      wrap: isMobile ? true : false,
    }),
  ];

  return (
    <ResponsiveLine
      data={dataSet}
      margin={margin}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        reverse: false,
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: isMobile ? -45 : 0,
        legend: 'Years',
        legendOffset: 36,
        legendPosition: 'middle',
        truncateTickAt: 0,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: '$/MWh',
        legendOffset: -40,
        legendPosition: 'middle',
        truncateTickAt: 0,
      }}
      enableTouchCrosshair={true}
      useMesh={true}
      legends={legends}
      tooltip={({ point }) => (
        <div className="bg-white p-2 shadow-lg rounded-lg border">
          <strong>{point.serieId}</strong>: {point.data.y.toString()} $/MWh
          <div className="text-sm text-gray-600">{point.data.x.toString()}</div>
        </div>
      )}
    />
  );
};
