import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { useAuth } from '@/components/auth/AuthProvider';
import { Layout } from '@/components/common/Layout';

export const RootLayout = () => {
  const location = useLocation();
  const { session } = useAuth();

  useEffect(() => {
    if (session) {
      Sentry.setUser({ id: session.user.id, email: session.user.email });
    } else {
      Sentry.setUser(null);
    }
  }, [session]);

  return (
    <Layout>
      <Outlet key={location.pathname} />
    </Layout>
  );
};
