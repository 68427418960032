import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'sonner';

import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Loader } from '@/components/ui/loader';
import { supabase } from '@/lib/supabase/config';

export const ForgotPassword = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      email: '',
    },
  });

  const handleForgotPassword = async ({ email }: { email: string }) => {
    try {
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: `${window.location.origin}/reset-password`,
      });
      if (error) {
        toast.error(t('forgotPassword.error'), {
          description: error.message,
        });
      } else {
        toast.success(t('forgotPassword.checkEmailMsg'));
      }
    } catch (err) {
      toast.error(t('forgotPassword.error'), {
        description: JSON.stringify(err),
      });
    }
  };

  return (
    <div className="container relative h-screen flex-col items-center justify-center grid lg:max-w-[840px] mx-auto lg:grid-cols-2 lg:px-0 overflow-auto">
      <div className="relative hidden bg-background h-full p-10 lg:flex items-center justify-center flex-col">
        <div>
          <img alt="logo" src="/energico.png" className="h-16" />
          <blockquote className="space-y-2">
            <p className="text-md">{t('forgotPassword.punchline')}</p>
          </blockquote>
        </div>
      </div>
      <div className="lg:p-8">
        <div className="mx-auto flex w-full flex-col justify-center space-y-6 max-w-[350px]">
          <div className="flex flex-col space-y-2 text-center">
            <h1 className="text-2xl font-semibold tracking-tight">
              {t('forgotPassword.title')}
            </h1>
            <p className="text-sm text-muted-foreground">
              {t('forgotPassword.subtitle')}
            </p>
          </div>
          <form onSubmit={handleSubmit(handleForgotPassword)}>
            <div className="grid gap-2">
              <div className="grid gap-1">
                <Label className="sr-only" htmlFor="email">
                  {t('forgotPassword.email')}
                </Label>
                <Input
                  id="email"
                  placeholder={t('forgotPassword.emailPlaceholder')}
                  type="email"
                  autoCapitalize="none"
                  autoComplete="email"
                  autoCorrect="off"
                  {...register('email', {
                    required: true,
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: t('forgotPassword.emailInvalid'),
                    },
                  })}
                />
              </div>
              <Button disabled={isSubmitting}>
                {isSubmitting && <Loader className="mr-1" />}
                {t('forgotPassword.cta')}
              </Button>
            </div>
          </form>
          <p className="px-8 text-center text-sm text-muted-foreground">
            <Trans i18nKey="forgotPassword.termsAndPrivacy">
              By clicking continue, you agree to our
              <a
                href="https://www.energico.io/terms-and-conditions-app"
                target="_blank"
                rel="noreferrer"
                className="underline underline-offset-4 hover:text-primary"
              >
                Terms of Service
              </a>
              .
            </Trans>
          </p>
        </div>
      </div>
    </div>
  );
};
