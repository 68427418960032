import { CheckCircle2Icon } from 'lucide-react';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';

import { useUserCommunity } from '../community/hooks/useUserCommunity';
import {
  isMemberReadyForContractSigning,
  useIsMemberFinishedRegistration,
} from '../member/hooks/useIsMemberFinishedRegistration';

type Props = {
  mode?: 'registration' | 'profile';
};

export const RegistrationNavigationTabs = ({ mode = 'registration' }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const pathWithoutQuery = location.pathname.split('?')[0];
  const segments = pathWithoutQuery.split('/');
  const currentTab = segments.pop() || '';
  const { member, isLoading } = useUserCommunity();
  const activeTabRef = useRef<HTMLButtonElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const {
    isMemberTypeStepCompleted,
    isMemberRoleStepCompleted,
    isMemberDetailsStepCompleted,
    isEnergyInformationStepCompleted,
    isLocationInformationStepCompleted,
    isFinancialInformationStepCompleted,
  } = useIsMemberFinishedRegistration();

  useEffect(() => {
    if (activeTabRef.current && scrollContainerRef.current) {
      const container = scrollContainerRef.current;
      const activeTab = activeTabRef.current;

      const scrollLeft =
        activeTab.offsetLeft - container.offsetWidth / 2 + activeTab.offsetWidth / 2;

      container.scrollTo({
        left: scrollLeft,
        behavior: 'smooth',
      });
    }
  }, [currentTab]);

  if (isLoading || !member) {
    return null;
  }

  return (
    <div ref={scrollContainerRef} className="w-full overflow-x-auto pb-2">
      <Tabs
        className="w-[400px] min-w-max"
        defaultValue="select-member-type"
        value={currentTab}
        onValueChange={(value) => navigate(value)}
      >
        <TabsList className="w-full">
          <TabsTrigger
            ref={currentTab === 'select-member-type' ? activeTabRef : undefined}
            onClick={() => navigate('select-member-type')}
            value="select-member-type"
            className="flex items-center gap-2 whitespace-nowrap"
          >
            {mode === 'registration' && isMemberTypeStepCompleted && (
              <CheckCircle2Icon className="text-success" size="16" />
            )}
            {t('registration.tabs.selectMemberType')}
          </TabsTrigger>
          <TabsTrigger
            ref={currentTab === 'choose-your-role' ? activeTabRef : undefined}
            onClick={() => navigate('choose-your-role')}
            value="choose-your-role"
            className="flex items-center gap-2 whitespace-nowrap"
          >
            {mode === 'registration' && isMemberRoleStepCompleted && (
              <CheckCircle2Icon className="text-success" size="16" />
            )}
            {t('registration.tabs.chooseYourRole')}
          </TabsTrigger>
          <TabsTrigger
            ref={currentTab === 'your-details' ? activeTabRef : undefined}
            onClick={() => navigate('your-details')}
            value="your-details"
            className="flex items-center gap-2 whitespace-nowrap"
          >
            {mode === 'registration' && isMemberDetailsStepCompleted && (
              <CheckCircle2Icon className="text-success" size="16" />
            )}
            {t('registration.tabs.memberDetails')}
          </TabsTrigger>
          <TabsTrigger
            ref={currentTab === 'energy-information' ? activeTabRef : undefined}
            onClick={() => navigate('energy-information')}
            className="flex items-center gap-2 whitespace-nowrap"
            value="energy-information"
          >
            {mode === 'registration' && isEnergyInformationStepCompleted && (
              <CheckCircle2Icon className="text-success" size="16" />
            )}
            {t('registration.tabs.energyInformation')}
          </TabsTrigger>
          <TabsTrigger
            ref={currentTab === 'location-information' ? activeTabRef : undefined}
            onClick={() => navigate('location-information')}
            className="flex items-center gap-2 whitespace-nowrap"
            value="location-information"
          >
            {mode === 'registration' && isLocationInformationStepCompleted && (
              <CheckCircle2Icon className="text-success" size="16" />
            )}
            {t('registration.tabs.locationInformation')}
          </TabsTrigger>
          <TabsTrigger
            ref={currentTab === 'financial-information' ? activeTabRef : undefined}
            onClick={() => navigate('financial-information')}
            className="flex items-center gap-2 whitespace-nowrap"
            value="financial-information"
          >
            {mode === 'registration' && isFinancialInformationStepCompleted && (
              <CheckCircle2Icon className="text-success" size="16" />
            )}
            {t('registration.tabs.financialInformation')}
          </TabsTrigger>
          {mode === 'registration' && (
            <TabsTrigger
              ref={currentTab === 'review-sign-contract' ? activeTabRef : undefined}
              onClick={() => navigate('review-sign-contract')}
              value="review-sign-contract"
              className="whitespace-nowrap"
              disabled={!isMemberReadyForContractSigning(member)}
            >
              {t('registration.tabs.reviewAndSignContract')}
            </TabsTrigger>
          )}
        </TabsList>
      </Tabs>
    </div>
  );
};
