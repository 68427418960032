import { Navigate, Outlet, useNavigate } from 'react-router-dom';

import { LogoutButton } from '@/components/auth/LogoutButton';
import {
  UserCommunityProvider,
  useUserCommunity,
} from '@/components/community/hooks/useUserCommunity';
import { useIsMemberFinishedRegistration } from '@/components/member/hooks/useIsMemberFinishedRegistration';
import { RegistrationNavigationTabs } from '@/components/registration/RegistrationNavigationTabs';
import StopImpersonating from '@/components/superadmin/StopImpersonating';

const RegistrationLayoutInternal = () => {
  const { isMemberFinishedRegistration } = useIsMemberFinishedRegistration();
  const { member } = useUserCommunity();
  const navigate = useNavigate();

  if (isMemberFinishedRegistration && !member?.is_admin) {
    return <Navigate to={'/community'} />;
  }

  return (
    <div className="h-screen flex max-sm:flex-col">
      <div className="flex flex-col max-sm:flex-row max-sm:justify-between max-sm:items-center max-sm:w-full max-sm:border-b">
        <button
          className="w-[240px] max-sm:w-auto shrink-0 px-6 py-7 max-sm:py-4 self-start"
          onClick={() => {
            navigate('/');
          }}
        >
          <img className="w-[173px] max-sm:w-[120px]" src="/energico.png" alt="" />
        </button>
        <LogoutButton className="w-full mt-auto mx-6 mb-6 max-sm:my-4" />
      </div>
      <div className="space-y-5 py-8 max-sm:py-4 overflow-auto px-10 max-sm:px-4 grow">
        <RegistrationNavigationTabs />
        <Outlet />
      </div>
    </div>
  );
};

export const RegistrationLayout = () => {
  return (
    <UserCommunityProvider>
      <StopImpersonating />
      <RegistrationLayoutInternal />
    </UserCommunityProvider>
  );
};
