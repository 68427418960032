import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

import { useAuth } from '@/components/auth/AuthProvider';
import { Member } from '@/components/community/types/member';
import { fetchWithSupabase } from '@/lib/api/fetch';
import { memberQueryKey } from '@/lib/api/queryKeys';
import { UpdateMemberProfileResponse } from '@/lib/api/types';
import { UPDATE_MEMBER_URL } from '@/lib/api/url';

type MemberData = Partial<
  Pick<
    Member,
    | 'type'
    | 'member_role'
    | 'first_name'
    | 'last_name'
    | 'phone_number'
    | 'fiscal_number'
    | 'birth_date'
    | 'birth_place_country'
    | 'birth_place_region'
    | 'birth_place_city'
    | 'birth_place_municipality'
    | 'company_name'
    | 'vat_number'
    | 'pod_id'
    | 'has_received_contract'
    | 'created_at'
    | 'id'
    | 'is_admin'
    | 'community_id'
    | 'vat_number'
    // Add other member-related fields here
  >
>;

interface UseUpdateMemberOptions {
  onSuccess: (member: Member) => void;
  onError?: () => void;
}

export const useUpdateMember = ({ onSuccess, onError }: UseUpdateMemberOptions) => {
  const { t } = useTranslation();
  const { session } = useAuth();
  const queryClient = useQueryClient();

  const defaultErrorHandler = () => {
    toast.error(t('profile.form.error'));
  };

  const { mutate, isPending } = useMutation({
    mutationFn: async (data: MemberData) => {
      if (!session) {
        throw new Error('User not authenticated');
      }

      const response = await fetchWithSupabase<UpdateMemberProfileResponse>(
        UPDATE_MEMBER_URL(session.user.id),
        {
          method: 'POST',
          body: JSON.stringify(data),
        },
      );

      if (!response.success) {
        throw new Error('API request failed');
      }

      return response.member;
    },
    onError: onError || defaultErrorHandler,
    onSuccess: (data) => {
      onSuccess(data);
      queryClient.invalidateQueries({ queryKey: [memberQueryKey, session?.user.id] });
    },
  });

  return { mutate, isPending };
};
