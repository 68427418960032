import { useQuery } from '@tanstack/react-query';
import constate from 'constate';

import { useAuth } from '@/components/auth/AuthProvider';
import { fetchWithSupabase } from '@/lib/api/fetch';
import { memberQueryKey, userCommunitiesQueryKey } from '@/lib/api/queryKeys';
import { MemberProfileResponse, UserCommunitiesResponse } from '@/lib/api/types';
import { GET_COMMUNITIES_URL, GET_MEMBER_URL } from '@/lib/api/url';

import { Community } from '../types/community';

const useUserCommunityInternal = () => {
  const { session } = useAuth();

  const { data: community, isLoading: isLoadingCommunity } = useQuery({
    queryKey: [userCommunitiesQueryKey],
    queryFn: async () => {
      const { success, communities } = await fetchWithSupabase<UserCommunitiesResponse>(
        GET_COMMUNITIES_URL,
        {
          method: 'GET',
        },
      );

      if (success === false || communities.length === 0) {
        return null;
      }

      const firstCommunity = communities[0];

      return {
        ...firstCommunity,
        is_admin: firstCommunity.members ? firstCommunity.members[0]!.is_admin : false,
      };
    },
    enabled: !!session,
  });

  const { data: member, isLoading: isLoadingMember } = useQuery({
    queryKey: [memberQueryKey, session?.user.id],
    queryFn: async () => {
      if (!session) {
        return null;
      }

      const res = await fetchWithSupabase<MemberProfileResponse>(
        GET_MEMBER_URL(session.user.id),
        {
          method: 'GET',
        },
      );

      if (res.success === false || !res.member) {
        return null;
      }

      return res.member;
    },
    enabled: !!session && !!community,
  });

  return {
    community,
    member,
    isLoading: isLoadingCommunity || isLoadingMember,
  };
};

export const [UserCommunityProvider, useUserCommunity] = constate(
  useUserCommunityInternal,
);

export const hasCommunityInfoFilled = (community: Community) => {
  return (
    !!community.name &&
    !!community.activation_date &&
    !!community.pec_email_address &&
    !!community.iban &&
    community.use_dedicated_withdrawal !== undefined
  );
};
