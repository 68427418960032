import {
  FilesIcon,
  Gauge,
  LayoutTemplate,
  LucideIcon,
  Menu,
  Settings,
  UserRoundCog,
  UserRoundPlusIcon,
  UsersRound,
  X,
} from 'lucide-react';
import { MouseEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';

import { LogoutButton } from '../auth/LogoutButton';
import { useUserCommunity } from '../community/hooks/useUserCommunity';
import { InviteMembersModal } from '../community/InviteMembersModal';
import { Button } from '../ui/button';

interface SidebarProps {
  superadmin?: boolean;
}

export const Sidebar = ({ superadmin = false }: SidebarProps) => {
  const navigate = useNavigate();
  const { community, member } = useUserCommunity();
  const [isInviteMembersModalOpen, setIsInviteMembersModalOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const { t } = useTranslation();

  const sidebarContent = (
    <div className="flex flex-col h-full">
      <div className="p-3">
        <div className="flex items-center justify-between mb-4">
          <button
            className="ml-4"
            onClick={() => {
              navigate('/');
            }}
          >
            <img width="173" src="/energico.png" alt="" />
          </button>
          <Button
            variant="ghost"
            className="md:hidden"
            onClick={() => setIsMobileMenuOpen(false)}
          >
            <X className="size-6" />
          </Button>
        </div>

        {/* Navigation Items */}
        <div className="flex flex-col gap-1">
          {superadmin ? (
            <NavItem
              onClick={() => setIsMobileMenuOpen(false)}
              text="Superadmin"
              to="/"
              Icon={UserRoundCog}
            />
          ) : (
            <>
              <NavItem
                onClick={() => setIsMobileMenuOpen(false)}
                text={t('sidebar.tabs.dashboard')}
                to="/"
                Icon={Gauge}
              />
              {community?.is_admin && (
                <>
                  <NavItem
                    onClick={() => setIsMobileMenuOpen(false)}
                    text={t('sidebar.tabs.community')}
                    to="/community"
                    Icon={LayoutTemplate}
                  />
                  <NavItem
                    onClick={() => setIsMobileMenuOpen(false)}
                    text={t('sidebar.tabs.members')}
                    to="/community/members"
                    Icon={UsersRound}
                  />
                  <Button
                    variant="ghost"
                    className="w-full justify-start"
                    onClick={() => setIsInviteMembersModalOpen(true)}
                  >
                    <UserRoundPlusIcon className="mr-2 shrink-0 size-4" />
                    {t('community.inviteMembers')}
                  </Button>
                  <InviteMembersModal
                    communityId={community?.id}
                    open={isInviteMembersModalOpen}
                    onOpenChange={() => setIsInviteMembersModalOpen(false)}
                  />
                </>
              )}
              <NavItem
                onClick={() => setIsMobileMenuOpen(false)}
                text={t('sidebar.tabs.profile')}
                to={
                  member?.is_admin
                    ? '/profile/admin'
                    : '/profile/member/select-member-type'
                }
                Icon={UserRoundCog}
              />
              <NavItem
                onClick={() => setIsMobileMenuOpen(false)}
                text={t('sidebar.tabs.documents')}
                to="/documents"
                Icon={FilesIcon}
              />
              <NavItem
                onClick={() => setIsMobileMenuOpen(false)}
                text={t('sidebar.tabs.settings')}
                to="/settings"
                Icon={Settings}
              />
            </>
          )}
        </div>
      </div>

      {/* Logout Button Container */}
      <div className="mt-auto p-3">
        <LogoutButton className="w-full" />
      </div>
    </div>
  );

  return (
    <>
      {/* Desktop Sidebar */}
      <div className="hidden md:block w-[240px] shrink-0 h-screen">{sidebarContent}</div>

      {/* Mobile Menu Button */}
      <div className="fixed top-0 left-0 right-0 z-20 md:hidden bg-white shadow-sm">
        <div className="flex items-center p-4">
          <Button
            variant="ghost"
            className="mr-4"
            onClick={() => setIsMobileMenuOpen(true)}
          >
            <Menu className="size-6" />
          </Button>
          <button
            onClick={() => {
              navigate('/');
            }}
          >
            <img width="173" src="/energico.png" alt="" />
          </button>
        </div>
      </div>

      {/* Mobile Sidebar */}
      <div
        className={`fixed inset-0 bg-background z-50 md:hidden transform transition-transform duration-300 ease-in-out ${
          isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        {sidebarContent}
      </div>
    </>
  );
};

interface NavItemProps {
  text: string;
  to: string;
  Icon: LucideIcon;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

const NavItem = ({ text, to, Icon, onClick }: NavItemProps) => {
  return (
    <NavLink onClick={onClick} to={to} end>
      {(props) => (
        <Button
          variant={props.isActive ? 'secondary' : 'ghost'}
          className="w-full justify-start"
        >
          <Icon className="mr-2 shrink-0 size-4" />
          {text}
        </Button>
      )}
    </NavLink>
  );
};

export default Sidebar;
