import { useTranslation } from 'react-i18next';

import { cn } from '@/lib/utils';

import { Button } from '../ui/button';
import { Checkbox } from '../ui/checkbox';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
} from '../ui/form';
import { Loader } from '../ui/loader';
import { useMemberForm } from './hooks/useMemberForm';
import { MemberFormParams } from './types/memberForm';

export const FinancialInformationForm = ({
  member,
  title,
  submitText,
  cancel,
  onSuccess,
}: MemberFormParams) => {
  const { t } = useTranslation();
  const { onSubmit, isPending, ...form } = useMemberForm(member, onSuccess);

  return (
    <div className="flex flex-col gap-8">
      {title}
      <Form {...form}>
        <form className="max-w-[500px]" onSubmit={onSubmit}>
          <FormField
            name="is_isee"
            control={form.control}
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4 mb-5">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={(checked) => {
                      form.setValue('is_isee', Boolean(checked));
                    }}
                  />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel>
                    {t('registration.financialInformation.isee.title')}
                  </FormLabel>
                  <FormDescription>
                    <ul className="list-disc">
                      <li className="mb-2 mt-3">
                        {t('registration.financialInformation.isee.description1')}
                      </li>
                      <li className="mb-2">
                        {t('registration.financialInformation.isee.description2')}
                      </li>
                      <li>{t('registration.financialInformation.isee.description3')}</li>
                    </ul>
                  </FormDescription>
                </div>
              </FormItem>
            )}
          />
          <div className="mt-8 flex flex-col sm:flex-row gap-2 sm:gap-0">
            {cancel && (
              <Button
                type="button"
                variant="secondary"
                className="mr-4"
                onClick={cancel.onClick}
              >
                {cancel.text}
              </Button>
            )}
            <Button
              type="submit"
              className={cn({ 'cursor-progress': isPending })}
              disabled={isPending}
            >
              {isPending && <Loader className="mr-1" />}
              {submitText}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};
