import { useQuery } from '@tanstack/react-query';
import { LogOutIcon } from 'lucide-react';
import { Trans, useTranslation } from 'react-i18next';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';

import { useAuth } from '@/components/auth/AuthProvider';
import { LogoutButton } from '@/components/auth/LogoutButton';
import { JoinCommunity } from '@/components/community/JoinCommunity';
import { Button } from '@/components/ui/button';
import { Callout } from '@/components/ui/custom/callout';
import { Loader } from '@/components/ui/loader';
import { fetchWithSupabase } from '@/lib/api/fetch';
import { InvitationInfoResponse } from '@/lib/api/types';
import { GET_INVITATION_INFO } from '@/lib/api/url';
import { supabase } from '@/lib/supabase/config';
import { removeTrailingCharacter } from '@/lib/utils';

export const JoinCommunityPage = () => {
  const { t } = useTranslation();
  const { invitationCode } = useParams();
  const { session } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const { data, isLoading, error } = useQuery({
    queryKey: ['invitation-info', invitationCode],
    queryFn: async () => {
      return fetchWithSupabase<InvitationInfoResponse>(
        GET_INVITATION_INFO(
          invitationCode ? removeTrailingCharacter(invitationCode) : '',
        ),
        {
          method: 'GET',
        },
      );
    },
    retry: false,
    enabled: !!invitationCode,
  });

  const renderContent = () => {
    if (!invitationCode) {
      return (
        <>
          <p>{t('community.invitations.join.invalid')}</p>
          <LogoutButton />
        </>
      );
    }

    if (isLoading) {
      return (
        <>
          <Loader />
          <p>{t('community.invitations.join.loading')}</p>
        </>
      );
    }

    if (error) {
      const errorMessage = t('community.invitations.join.error') + ' ' + error.message;
      return (
        <>
          <Callout text={errorMessage} />
          <LogoutButton />
        </>
      );
    }

    if (!data || data.invitation.status === 'REVOKED') {
      return (
        <>
          <Callout text={t('community.invitations.join.invalid')} />
          <LogoutButton />
        </>
      );
    }

    if (!session) {
      const currentPath = window.location.origin + location.pathname;

      return (
        <Navigate
          to={`/signup?next=${encodeURIComponent(currentPath)}&email=${encodeURIComponent(data.invitation.invitee_email)}&invitationCode=${invitationCode}`}
        />
      );
    }

    if (data.invitation.invitee_email !== session?.user.email) {
      return (
        <>
          <Callout
            className="max-w-[500px]"
            text={
              <Trans
                i18nKey="community.invitations.join.wrongAccount"
                values={{
                  invitee_email: data.invitation.invitee_email,
                  email: session?.user.email,
                }}
                components={{
                  1: <b />,
                  2: <b />,
                }}
              />
            }
          />

          <Button
            onClick={() => {
              const currentPath = window.location.origin + location.pathname;
              supabase.auth.signOut();
              navigate(
                `/signup?next=${encodeURIComponent(currentPath)}&email=${encodeURIComponent(data.invitation.invitee_email)}`,
              );
            }}
            className="w-fit justify-start mt-auto"
          >
            <LogOutIcon className="mr-2" />
            {t('sidebar.logout')}
          </Button>
        </>
      );
    }

    return <JoinCommunity code={invitationCode} />;
  };

  return (
    <div className="flex h-screen w-full flex-col items-center justify-center gap-4">
      {renderContent()}
    </div>
  );
};
