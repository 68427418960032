import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function removeTrailingCharacter(str: string): string {
  if (str.endsWith('#') || str.endsWith('?')) {
    return str.slice(0, -1);
  }
  return str;
}
