import { HomeIcon } from '@radix-ui/react-icons';
import { BuildingIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { cn } from '@/lib/utils';

import { Button } from '../ui/button';
import { Card } from '../ui/card';
import { Callout } from '../ui/custom/callout';
import { Loader } from '../ui/loader';
import { useMemberForm } from './hooks/useMemberForm';
import { MemberFormParams } from './types/memberForm';

export const MemberTypeForm = ({
  member,
  title,
  submitText,
  cancel,
  onSuccess,
}: MemberFormParams) => {
  const { t } = useTranslation();
  const { register, onSubmit, watch, isPending } = useMemberForm(
    {
      ...member,
      type: member.type || 'LEGAL_PERSON',
    },
    onSuccess,
  );

  const memberType = watch('type');

  return (
    <div className="overflow-auto flex flex-col gap-4 md:gap-8">
      {title}
      <form onSubmit={onSubmit}>
        <div className="flex flex-col md:flex-row gap-4">
          <label htmlFor="LEGAL_PERSON" className="w-full md:w-auto">
            <input
              {...register('type')}
              type="radio"
              className="hidden"
              id="LEGAL_PERSON"
              value="LEGAL_PERSON"
            />
            <Card
              className={cn(
                'w-full md:w-[172px] p-4 py-6 md:py-8 flex flex-col items-center gap-2 cursor-pointer',
                {
                  'bg-primary-background': memberType === 'LEGAL_PERSON',
                },
              )}
            >
              <HomeIcon className="w-8 h-8" />
              <div className="text-primary text-sm">
                {t('registration.selectMemberType.private')}
              </div>
            </Card>
          </label>
          <label htmlFor="PRIVATE_COMPANY" className="w-full md:w-auto">
            <input
              {...register('type')}
              type="radio"
              className="hidden"
              id="PRIVATE_COMPANY"
              value="PRIVATE_COMPANY"
            />
            <Card
              className={cn(
                'w-full md:w-[172px] p-4 py-6 md:py-8 flex flex-col items-center gap-2 cursor-pointer',
                {
                  'bg-primary-background': memberType === 'PRIVATE_COMPANY',
                },
              )}
            >
              <BuildingIcon className="w-8 h-8" />
              <div className="text-primary text-sm">
                {t('registration.selectMemberType.company')}
              </div>
            </Card>
          </label>
        </div>
        <div>
          {memberType === 'LEGAL_PERSON' && (
            <Callout
              className="mt-4"
              text={t('registration.selectMemberType.privateCallout')}
            />
          )}
          {memberType === 'PRIVATE_COMPANY' && (
            <Callout
              className="mt-4"
              text={t('registration.selectMemberType.companyCallout')}
            />
          )}
        </div>
        <div className="mt-8 flex flex-col sm:flex-row gap-2 sm:gap-0">
          {cancel && (
            <Button
              type="button"
              variant="secondary"
              className="sm:mr-4"
              onClick={cancel.onClick}
            >
              {cancel.text}
            </Button>
          )}
          <Button
            type="submit"
            className={cn({ 'cursor-progress': isPending })}
            disabled={isPending}
          >
            {isPending && <Loader className="mr-1" />}
            {submitText}
          </Button>
        </div>
      </form>
    </div>
  );
};
