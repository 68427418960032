import { useEffect, useState } from 'react';

import { ImpersonateUserResponse } from '@/lib/api/types';

const StopImpersonating = () => {
  const [impersonateData, setSuperadminLink] = useState<
    ImpersonateUserResponse['data'] | null
  >(null);

  useEffect(() => {
    const data = localStorage.getItem('impersonate_data');
    if (!data) {
      return;
    }
    setSuperadminLink(JSON.parse(data));
  }, []);

  return (
    impersonateData && (
      <div
        className="fixed bottom-4 left-1/2 -translate-x-1/2 z-50 w-auto px-6 py-3 bg-white/80 dark:bg-gray-800/80 rounded-lg shadow-lg backdrop-filter backdrop-blur-md"
        style={{
          maxWidth: 'calc(100vw - 2rem)',
        }}
      >
        <div className="flex items-center justify-between gap-8">
          <span className="text-sm text-gray-700 dark:text-gray-200">
            You are currently impersonating <strong>{impersonateData.email}</strong>.
          </span>
          <a
            href={impersonateData.superadmin_link}
            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={() => localStorage.removeItem('impersonate_data')}
          >
            Stop Impersonating
          </a>
        </div>
      </div>
    )
  );
};

export default StopImpersonating;
