import { useTranslation } from 'react-i18next';

import { cn } from '@/lib/utils';

import { Button } from '../ui/button';
import { AddressAutocomplete } from '../ui/custom/addressAutocomplete';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormMessage,
} from '../ui/form';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import { Loader } from '../ui/loader';
import { useMemberForm } from './hooks/useMemberForm';
import { MemberFormParams } from './types/memberForm';

export const LocationInformationForm = ({
  member,
  title,
  submitText,
  cancel,
  onSuccess,
}: MemberFormParams) => {
  const { t } = useTranslation();
  const { onSubmit, isPending, ...form } = useMemberForm(member, onSuccess);

  return (
    <div className="flex flex-col gap-8">
      {title}
      <Form {...form}>
        <form className="max-w-[500px]" onSubmit={onSubmit}>
          <FormField
            name="address"
            render={() => (
              <FormItem className="mb-6">
                <Label>{t('registration.locationInformation.title')}</Label>
                <FormControl>
                  <AddressAutocomplete
                    onAddressChange={(address) => {
                      Object.entries(address).forEach(([key, value]) => {
                        if (value && key in member) {
                          form.setValue(
                            key as Parameters<typeof form.setValue>[0],
                            value,
                          );
                        }
                      });

                      // Trigger validation
                      form.trigger([
                        'address_country',
                        'address_region',
                        'address_city',
                        'address_municipality',
                        'address_zip_code',
                        'address_route',
                        'address_street_number',
                      ]);
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* address_country */}
          <FormField
            name="address_country"
            control={form.control}
            render={() => (
              <FormItem className="mb-6">
                <Label>
                  {t('registration.locationInformation.addressCountry.fieldName')}
                </Label>
                <FormControl>
                  <Input
                    type="text"
                    {...form.register('address_country')}
                    placeholder={t(
                      'registration.locationInformation.addressCountry.fieldPlaceholder',
                    )}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {/* address_region */}
          <FormField
            name="address_region"
            control={form.control}
            render={() => (
              <FormItem className="mb-6">
                <Label>
                  {t('registration.locationInformation.addressRegion.fieldName')}
                </Label>
                <FormControl>
                  <Input
                    type="text"
                    {...form.register('address_region')}
                    placeholder={t(
                      'registration.locationInformation.addressRegion.fieldPlaceholder',
                    )}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {/* address_city */}
          <FormField
            name="address_city"
            control={form.control}
            render={() => (
              <FormItem className="mb-6">
                <Label>
                  {t('registration.locationInformation.addressCity.fieldName')}
                </Label>
                <FormControl>
                  <Input
                    type="text"
                    {...form.register('address_city')}
                    placeholder={t(
                      'registration.locationInformation.addressCity.fieldPlaceholder',
                    )}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {/* address_municipality */}
          <FormField
            name="address_municipality"
            control={form.control}
            render={() => (
              <FormItem className="mb-6">
                <Label>
                  {t('registration.locationInformation.addressMunicipality.fieldName')}
                </Label>
                <FormControl>
                  <Input
                    type="text"
                    {...form.register('address_municipality')}
                    placeholder={t(
                      'registration.locationInformation.addressMunicipality.fieldPlaceholder',
                    )}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {/* address_zip_code */}
          <FormField
            name="address_zip_code"
            control={form.control}
            render={() => (
              <FormItem className="mb-6">
                <Label>
                  {t('registration.locationInformation.addressZipCode.fieldName')}
                </Label>
                <FormControl>
                  <Input
                    type="text"
                    {...form.register('address_zip_code', {
                      pattern: {
                        value: /^\d{5}$/,
                        message: t(
                          'registration.locationInformation.addressZipCode.fieldError',
                        ),
                      },
                    })}
                    placeholder={t(
                      'registration.locationInformation.addressZipCode.fieldPlaceholder',
                    )}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {/* address_route */}
          <FormField
            name="address_route"
            control={form.control}
            render={() => (
              <FormItem className="mb-6">
                <Label>
                  {t('registration.locationInformation.addressStreetName.fieldName')}
                </Label>
                <FormControl>
                  <Input
                    type="text"
                    {...form.register('address_route')}
                    placeholder={t(
                      'registration.locationInformation.addressStreetName.fieldPlaceholder',
                    )}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {/* address_street_number */}
          <FormField
            name="address_street_number"
            control={form.control}
            render={() => (
              <FormItem className="mb-6">
                <Label>
                  {t('registration.locationInformation.addressStreetNumber.fieldName')}
                </Label>
                <FormControl>
                  <Input
                    type="text"
                    {...form.register('address_street_number')}
                    placeholder={t(
                      'registration.locationInformation.addressStreetNumber.fieldPlaceholder',
                    )}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {/* cadastral_section */}
          <div className="w-full h-[1px] bg-slate-300 mb-2 mt-10" />
          <Label className="text-slate-700">
            I dati catastali di un immobile sono il codice che permette di identificarlo.
            Puoi trovare i dati catastali nelle prime pagine del tuo atto di proprietà o
            nel tuo contratto di affitto.
          </Label>
          <FormField
            name="cadastral_section"
            control={form.control}
            render={() => (
              <FormItem className="mb-6 mt-6">
                <Label>
                  {t('registration.locationInformation.cadastralSection.fieldName')}
                </Label>
                <FormControl>
                  <Input
                    type="text"
                    {...form.register('cadastral_section')}
                    placeholder={t(
                      'registration.locationInformation.cadastralSection.fieldPlaceholder',
                    )}
                  />
                </FormControl>
                <FormDescription>
                  {t(
                    'registration.locationInformation.cadastralSection.fieldDescription',
                  )}
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          {/* cadastral_paper */}
          <FormField
            name="cadastral_paper"
            control={form.control}
            render={() => (
              <FormItem className="mb-6">
                <Label>
                  {t('registration.locationInformation.cadastralPaper.fieldName')}
                </Label>
                <FormControl>
                  <Input
                    type="text"
                    {...form.register('cadastral_paper')}
                    placeholder={t(
                      'registration.locationInformation.cadastralPaper.fieldPlaceholder',
                    )}
                  />
                </FormControl>
                <FormDescription>
                  {t('registration.locationInformation.cadastralPaper.fieldDescription')}
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          {/* cadastral_particle */}
          <FormField
            name="cadastral_particle"
            control={form.control}
            render={() => (
              <FormItem className="mb-6">
                <Label>
                  {t('registration.locationInformation.cadastralParticle.fieldName')}
                </Label>
                <FormControl>
                  <Input
                    type="text"
                    {...form.register('cadastral_particle')}
                    placeholder={t(
                      'registration.locationInformation.cadastralParticle.fieldPlaceholder',
                    )}
                  />
                </FormControl>
                <FormDescription>
                  {t(
                    'registration.locationInformation.cadastralParticle.fieldDescription',
                  )}
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          {/* cadastral_subsection */}
          <FormField
            name="cadastral_subsection"
            control={form.control}
            render={() => (
              <FormItem className="mb-6">
                <Label>
                  {t('registration.locationInformation.cadastralSubsection.fieldName')}
                </Label>
                <FormControl>
                  <Input
                    type="text"
                    {...form.register('cadastral_subsection')}
                    placeholder={t(
                      'registration.locationInformation.cadastralSubsection.fieldPlaceholder',
                    )}
                  />
                </FormControl>
                <FormDescription>
                  {t(
                    'registration.locationInformation.cadastralSubsection.fieldDescription',
                  )}
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="mt-8 flex flex-col sm:flex-row gap-2 sm:gap-0">
            {cancel && (
              <Button
                type="button"
                variant="secondary"
                className="mr-4"
                onClick={cancel.onClick}
              >
                {cancel.text}
              </Button>
            )}
            <Button
              type="submit"
              className={cn({ 'cursor-progress': isPending })}
              disabled={isPending}
            >
              {isPending && <Loader className="mr-1" />}
              {submitText}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};
