import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { CommunityInvitationsList } from '@/components/community/CommunityInvitationsList';
import { CommunityMembersList } from '@/components/community/CommunityMembersList';
import { useUserCommunity } from '@/components/community/hooks/useUserCommunity';
import { Title } from '@/components/ui/custom/title';

export const CommunityMembersPage = () => {
  const { community } = useUserCommunity();
  const { t } = useTranslation();

  if (!community || community.is_admin === false) {
    return <Navigate to="/" />;
  }

  return (
    <div className="flex flex-col gap-8">
      <div className="space-y-2">
        <Title title={t('community.members.listTitle')} />
        <CommunityMembersList communityId={community.id} />
      </div>
      <div className="space-y-2">
        <Title title={t('community.invitations.title')} />
        <CommunityInvitationsList communityId={community.id} />
      </div>
    </div>
  );
};
