import { useQueryClient } from '@tanstack/react-query';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

import { fetchFormDataWithSupabase } from '@/lib/api/fetch';
import { documentsQueryKey } from '@/lib/api/queryKeys';
import { UploadDocumentResponse } from '@/lib/api/types';
import { UPLOAD_DOCUMENT_URL } from '@/lib/api/url';

import { Button } from '../ui/button';
import { Form, FormControl, FormField, FormItem, FormMessage } from '../ui/form';
import { Input } from '../ui/input';
import { DocumentName } from './types/documents';

type FormValues = {
  file: FileList;
};

type DocumentUploaderProps = {
  filename: DocumentName;
};

export const DocumentUploader = ({ filename }: DocumentUploaderProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const form = useForm<FormValues>();

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const formData = new FormData();
    const originalFile = data.file[0];
    const newFile = new File([originalFile], filename, { type: originalFile.type });
    formData.append('file', newFile);
    formData.append('document_type', filename.toUpperCase());

    const uploadPromise = fetchFormDataWithSupabase<UploadDocumentResponse>(
      UPLOAD_DOCUMENT_URL,
      {
        method: 'POST',
        body: formData,
      },
    ).then(() => {
      queryClient.invalidateQueries({ queryKey: [documentsQueryKey] });
      form.reset();
    });

    toast.promise(uploadPromise, {
      loading: t('documents.upload.uploading'),
      success: t('documents.upload.success'),
      error: t('documents.upload.error'),
    });
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-6">
        <div className="max-w-96">
          <FormField
            control={form.control}
            name="file"
            rules={{
              required: t('documents.upload.required'),
              validate: {
                atLeastOneFile: (files) =>
                  files.length > 0 || t('documents.upload.required'),
                lessThan5MB: (files) =>
                  files[0]?.size < 5000000 || t('documents.upload.size'),
                acceptedFormats: (files) =>
                  [
                    'image/jpeg',
                    'image/png',
                    'text/plain',
                    'application/pdf',
                    'application/msword',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  ].includes(files[0]?.type) || t('documents.upload.format'),
              },
            }}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    disabled={form.formState.isSubmitting}
                    id="file"
                    type="file"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      field.onChange(e.target.files)
                    }
                    accept=".jpg, .jpeg, .png, .txt, .pdf, .doc, .docx"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <Button
          disabled={form.formState.isSubmitting}
          type="submit"
          className="w-fit"
          size="sm"
        >
          {t('documents.upload.cta')}
        </Button>
      </form>
    </Form>
  );
};
