import { format } from 'date-fns';
import { it } from 'date-fns/locale';
import { CalendarIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { cn } from '@/lib/utils';

import { useAuth } from '../auth/AuthProvider';
import { Button } from '../ui/button';
import { Calendar } from '../ui/calendar';
import { Checkbox } from '../ui/checkbox';
import { AddressAutocomplete } from '../ui/custom/addressAutocomplete';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import { Loader } from '../ui/loader';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import { RadioGroup, RadioGroupItem } from '../ui/radio-group';
import { useMemberForm } from './hooks/useMemberForm';
import { MemberFormParams } from './types/memberForm';

export const MemberDetailsForm = ({
  member,
  title,
  submitText,
  cancel,
  onSuccess,
}: MemberFormParams) => {
  const form = useMemberForm(member, onSuccess);

  return (
    <div className="flex flex-col gap-8">
      {title}
      <Form {...form}>
        <form className="max-w-[500px]" onSubmit={form.onSubmit}>
          {member.type === 'PRIVATE_COMPANY' ? (
            <PrivateCompanyFields form={form} />
          ) : (
            // Default to legal person
            <LegalPersonFields form={form} />
          )}
          <div className="mt-8 flex flex-col sm:flex-row gap-2 sm:gap-0">
            {cancel && (
              <Button
                type="button"
                variant="secondary"
                className="mr-4"
                onClick={cancel.onClick}
              >
                {cancel.text}
              </Button>
            )}
            <Button
              type="submit"
              className={cn({ 'cursor-progress': form.isPending })}
              disabled={form.isPending}
            >
              {form.isPending && <Loader className="mr-1" />}
              {submitText}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

const PrivateCompanyFields = ({ form }: { form: ReturnType<typeof useMemberForm> }) => {
  const { t } = useTranslation();
  const { session } = useAuth();

  return (
    <>
      <FormItem className="mb-6">
        <Label>{t('community.members.form.email')}</Label>
        <FormControl>
          <Input id="email" type="text" value={session?.user.email} disabled />
        </FormControl>
        <FormMessage />
      </FormItem>
      <FormField
        name="company_name"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('registration.organisationDetails.companyName')}</Label>
            <FormControl>
              <Input
                placeholder={t('registration.organisationDetails.companyNamePlaceholder')}
                {...form.register('company_name', { required: true })}
                {...field}
              />
            </FormControl>
            <FormMessage />
            <FormDescription>
              {t('registration.organisationDetails.companyNameDescription')}
            </FormDescription>
          </FormItem>
        )}
      />
      <FormField
        name="company_fiscal_number"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('registration.organisationDetails.companyFiscalNumber')}</Label>
            <FormControl>
              <Input
                placeholder={t(
                  'registration.organisationDetails.companyFiscalNumberPlaceholder',
                )}
                {...form.register('company_fiscal_number', { required: true })}
                {...field}
              />
            </FormControl>
            <FormDescription>
              {t('registration.organisationDetails.companyFiscalNumberDescription')}
            </FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        name="vat_number"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('registration.organisationDetails.vatNumber')}</Label>
            <FormControl>
              <Input
                placeholder={t('registration.organisationDetails.vatNumberPlaceholder')}
                {...form.register('vat_number', { required: true })}
                {...field}
              />
            </FormControl>
            <FormDescription>
              {t('registration.organisationDetails.vatNumberDescription')}
            </FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        name="phone_number"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('registration.organisationDetails.phoneNumber')}</Label>
            <FormControl>
              <Input
                placeholder={t('registration.organisationDetails.phoneNumberPlaceholder')}
                {...form.register('phone_number', { required: true })}
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        name="ateco_code"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('registration.organisationDetails.atecoCode')}</Label>
            <FormControl>
              <Input
                placeholder={t('registration.organisationDetails.atecoCode')}
                {...form.register('ateco_code', { required: true })}
                {...field}
              />
            </FormControl>
            <FormDescription>
              {t('registration.organisationDetails.atecoCodeDescription')}
            </FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />
      {/* business_report */}
      <FormField
        name="business_report"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('registration.organisationDetails.businessReport')}</Label>
            <FormControl>
              <Input
                placeholder={t(
                  'registration.organisationDetails.businessReportPlaceholder',
                )}
                {...form.register('business_report', { required: true })}
                {...field}
              />
            </FormControl>
            <FormDescription>
              {t('registration.organisationDetails.businessReportDescription')}
            </FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        name="is_associate_firm"
        control={form.control}
        render={({ field }) => (
          <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
            <FormControl>
              <Checkbox
                checked={field.value}
                onCheckedChange={(checked) => {
                  form.setValue('is_associate_firm', Boolean(checked));
                }}
              />
            </FormControl>
            <div className="space-y-1 leading-none">
              <FormLabel>
                {t('registration.organisationDetails.isAssociateFirm')}
              </FormLabel>
            </div>
          </FormItem>
        )}
      />
      <FormField
        name="subject_type"
        control={form.control}
        render={({ field }) => (
          <FormItem className="space-y-3 mt-4">
            <FormLabel>{t('registration.organisationDetails.subjectType')}</FormLabel>
            <FormControl>
              <RadioGroup
                onValueChange={field.onChange}
                defaultValue={field.value}
                className="flex flex-col space-y-1"
              >
                <FormItem className="flex items-center space-x-3 space-y-0">
                  <FormControl>
                    <RadioGroupItem value="LOCAL_ENTITY" />
                  </FormControl>
                  {/* "LOCAL_ENTITY" | "SME" | "PRIVATE_LAW_ASSOCIATION" | "OTHER" */}
                  <FormLabel className="font-normal">
                    {t(
                      'registration.organisationDetails.subjectTypeOptions.subjectTypeLocalEntity',
                    )}
                  </FormLabel>
                </FormItem>
                <FormItem className="flex items-center space-x-3 space-y-0">
                  <FormControl>
                    <RadioGroupItem value="SME" />
                  </FormControl>
                  <FormLabel className="font-normal">
                    {t('registration.organisationDetails.subjectTypeOptions.sme')}
                  </FormLabel>
                </FormItem>
                <FormItem className="flex items-center space-x-3 space-y-0">
                  <FormControl>
                    <RadioGroupItem value="PRIVATE_LAW_ASSOCIATION" />
                  </FormControl>
                  <FormLabel className="font-normal">
                    {t(
                      'registration.organisationDetails.subjectTypeOptions.privateLawAssociation',
                    )}
                  </FormLabel>
                </FormItem>
                <FormItem className="flex items-center space-x-3 space-y-0">
                  <FormControl>
                    <RadioGroupItem value="OTHER" />
                  </FormControl>
                  <FormLabel className="font-normal">
                    {t('registration.organisationDetails.subjectTypeOptions.other')}
                  </FormLabel>
                </FormItem>
              </RadioGroup>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        name="legal_representative_name"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6 mt-4">
            <Label>{t('registration.organisationDetails.legalRepresentativeName')}</Label>
            <FormControl>
              <Input
                placeholder={t(
                  'registration.organisationDetails.legalRepresentativeNamePlaceholder',
                )}
                {...form.register('legal_representative_name', { required: true })}
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        name="fiscal_number"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('community.members.form.fiscalNumber')}</Label>
            <FormControl>
              <Input {...form.register('fiscal_number', { required: true })} {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      {/* birth_date */}
      <FormField
        control={form.control}
        name="birth_date"
        render={({ field }) => (
          <FormItem className="mb-6 flex flex-col">
            <Label>{t('community.members.form.birthDate')}</Label>
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant={'outline'}
                  className={cn(
                    'justify-start text-left font-normal',
                    !field.value && 'text-muted-foreground',
                  )}
                >
                  <CalendarIcon className="mr-2 h-4 w-4" />
                  {field.value ? (
                    format(field.value, 'PPP', { locale: it }) // TODO: use i18n
                  ) : (
                    <span>{t('misc.selectDate')}</span>
                  )}
                </Button>
              </PopoverTrigger>
              <PopoverContent align="start" className=" w-auto p-0">
                <Calendar
                  locale={it}
                  mode="single"
                  captionLayout="dropdown-buttons"
                  selected={new Date(field.value ?? '')}
                  onSelect={(date) => {
                    if (!date) return;
                    const utcDate = new Date(
                      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
                    );
                    form.setValue('birth_date', utcDate.toISOString());
                  }}
                  fromYear={1900}
                  toYear={2030}
                />
              </PopoverContent>
            </Popover>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        name="address"
        // control={form.control}
        render={() => (
          <FormItem className="mb-6">
            <Label>{t('registration.memberDetails.birthPlace')}</Label>{' '}
            <FormControl>
              <AddressAutocomplete
                placeholder={t('registration.memberDetails.birthPlaceCityPlaceholder')}
                types={['(cities)']}
                onAddressChange={(address) => {
                  form.setValue('birth_place_country', address.address_country);
                  form.setValue('birth_place_region', address.address_region);
                  form.setValue('birth_place_city', address.address_city);
                  form.setValue('birth_place_municipality', address.address_municipality);
                }}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      {/* birth_place_country */}
      <FormField
        name="birth_place_country"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('registration.memberDetails.birthPlaceCountry')}</Label>
            <FormControl>
              <Input
                {...form.register('birth_place_country', { required: true })}
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      {/* birth_place_region */}
      <FormField
        name="birth_place_region"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('registration.memberDetails.birthPlaceRegion')}</Label>
            <FormControl>
              <Input
                {...form.register('birth_place_region', { required: true })}
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      {/* birth_place_city */}
      <FormField
        name="birth_place_city"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('registration.memberDetails.birthPlaceProvince')}</Label>
            <FormControl>
              <Input
                {...form.register('birth_place_city', { required: true })}
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      {/* birth_place_municipality */}
      <FormField
        name="birth_place_municipality"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('registration.memberDetails.birthPlaceMunicipality')}</Label>
            <FormControl>
              <Input
                {...form.register('birth_place_municipality', { required: true })}
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </>
  );
};

const LegalPersonFields = ({ form }: { form: ReturnType<typeof useMemberForm> }) => {
  const { t } = useTranslation();
  const { session } = useAuth();

  return (
    <>
      <FormItem className="mb-6">
        <Label>{t('community.members.form.email')}</Label>
        <FormControl>
          <Input id="email" type="text" value={session?.user.email} disabled />
        </FormControl>
        <FormMessage />
      </FormItem>
      <FormField
        name="first_name"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('community.members.form.firstName')}</Label>
            <FormControl>
              <Input
                {...form.register('first_name', { required: true })}
                {...field}
                placeholder={t('community.members.form.firstNamePlaceholder')}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        name="last_name"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('community.members.form.lastName')}</Label>
            <FormControl>
              <Input
                {...form.register('last_name', { required: true })}
                {...field}
                placeholder={t('community.members.form.lastNamePlaceholder')}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        name="phone_number"
        //  TODO: use formatted phone input here
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('community.members.form.phoneNumber')}</Label>
            <FormControl>
              <Input {...form.register('phone_number', { required: true })} {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        name="fiscal_number"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('community.members.form.fiscalNumber')}</Label>
            <FormControl>
              <Input {...form.register('fiscal_number', { required: true })} {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        name="vat_number"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('registration.memberDetails.vatNumber')}</Label>
            <FormControl>
              <Input
                {...form.register('vat_number')}
                {...field}
                placeholder={t('registration.memberDetails.vatNumberPlaceholder')}
              />
            </FormControl>
            <FormDescription>
              {t('registration.memberDetails.vatNumberDescription')}
            </FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="birth_date"
        render={({ field }) => (
          <FormItem className="mb-6 flex flex-col">
            <Label>{t('community.members.form.birthDate')}</Label>
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant={'outline'}
                  className={cn(
                    'justify-start text-left font-normal',
                    !field.value && 'text-muted-foreground',
                  )}
                >
                  <CalendarIcon className="mr-2 h-4 w-4" />
                  {field.value ? (
                    format(field.value, 'PPP', { locale: it }) // TODO: use i18n
                  ) : (
                    <span>{t('misc.selectDate')}</span>
                  )}
                </Button>
              </PopoverTrigger>
              <PopoverContent align="start" className=" w-auto p-0">
                <Calendar
                  locale={it}
                  mode="single"
                  captionLayout="dropdown-buttons"
                  selected={new Date(field.value ?? '')}
                  onSelect={(date) => {
                    if (!date) return;
                    const utcDate = new Date(
                      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
                    );
                    form.setValue('birth_date', utcDate.toISOString());
                  }}
                  fromYear={1900}
                  toYear={2030}
                />
              </PopoverContent>
            </Popover>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        name="address"
        // control={form.control}
        render={() => (
          <FormItem className="mb-6">
            <Label>{t('registration.memberDetails.birthPlace')}</Label>{' '}
            <FormControl>
              <AddressAutocomplete
                placeholder={t('registration.memberDetails.birthPlaceCityPlaceholder')}
                types={['(cities)']}
                // defaultValue={field.value}
                onAddressChange={(address) => {
                  form.setValue('birth_place_country', address.address_country);
                  form.setValue('birth_place_region', address.address_region);
                  form.setValue('birth_place_city', address.address_city);
                  form.setValue('birth_place_municipality', address.address_municipality);
                }}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        name="birth_place_country"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('registration.memberDetails.birthPlaceCountry')}</Label>
            <FormControl>
              <Input
                {...form.register('birth_place_country', { required: true })}
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        name="birth_place_region"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('registration.memberDetails.birthPlaceRegion')}</Label>
            <FormControl>
              <Input
                {...form.register('birth_place_region', { required: true })}
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        name="birth_place_city"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('registration.memberDetails.birthPlaceProvince')}</Label>
            <FormControl>
              <Input
                {...form.register('birth_place_city', { required: true })}
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        name="birth_place_municipality"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('registration.memberDetails.birthPlaceMunicipality')}</Label>
            <FormControl>
              <Input
                {...form.register('birth_place_municipality', { required: true })}
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </>
  );
};
