import './index.css';
import './i18n';

import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PostHogProvider } from 'posthog-js/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, redirect, RouterProvider } from 'react-router-dom';

import { Toaster } from '@/components/ui/sonner';

import { AuthProvider } from './components/auth/AuthProvider.ts';
import { UserSupport } from './components/common/UserSupport.tsx';
import { AdminTabContent } from './components/profile/AdminTabContent.tsx';
import { MemberTabContent } from './components/profile/MemberTabContent.tsx';
import {
  RegistrationChooseRole,
  RegistrationEnergyInformation,
  RegistrationLocationInformation,
  RegistrationMemberDetails,
  RegistrationMemberType,
  RegistrationReviewSignContract,
  RegistrationUploadDocuments,
} from './components/registration';
import { RegistrationFinancialInformation } from './components/registration/RegistrationFinancialInformation.tsx';
import { supabase } from './lib/supabase/config.ts';
import { ForgotPassword } from './routes/auth/ForgotPassword.tsx';
import { Login } from './routes/auth/Login.tsx';
import { Register } from './routes/auth/Register.tsx';
import { ResetPassword } from './routes/auth/ResetPassword.tsx';
import { CommunityPage } from './routes/community/community.tsx';
import { CommunityMembersPage } from './routes/community/communityMembers.tsx';
import { ContractsPage } from './routes/contracts.tsx';
import { DashboardPage } from './routes/dashboard/dashboard.tsx';
import { DocumentsPage } from './routes/documentsPage.tsx';
import { JoinCommunityPage } from './routes/join/joinCommunity.tsx';
import { ProfilePage } from './routes/profile/profile.tsx';
import { RegistrationLayout } from './routes/registration/registration.layout.tsx';
import { RootLayout } from './routes/root.layout.tsx';
import { SettingsPage } from './routes/settingsPage.tsx';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});

const options = {
  api_host: import.meta.env.VITE_POSTHOG_HOST,
  mask_all_text: false,
  mask_all_element_attributes: false,
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const authLoader = async ({ request }: { request: Request }) => {
  const {
    data: { user },
  } = await supabase.auth.getUser();

  if (!user) {
    const url = new URL(request.url);
    return redirect(`/login?next=${encodeURIComponent(url.toString())}`);
  }

  return null;
};

const router = createBrowserRouter([
  { path: 'login', element: <Login /> },
  { path: 'signup', element: <Register /> },
  { path: 'forgot-password', element: <ForgotPassword /> },
  { path: 'reset-password', element: <ResetPassword /> },
  {
    path: 'join-community/:invitationCode',
    element: <JoinCommunityPage />,
  },
  {
    path: 'register',
    loader: authLoader,
    element: <RegistrationLayout />,
    children: [
      { path: 'select-member-type', element: <RegistrationMemberType /> },
      { path: 'choose-your-role', element: <RegistrationChooseRole /> },
      { path: 'your-details', element: <RegistrationMemberDetails /> },
      { path: 'energy-information', element: <RegistrationEnergyInformation /> },
      {
        path: 'location-information',
        element: <RegistrationLocationInformation />,
      },
      {
        path: 'financial-information',
        element: <RegistrationFinancialInformation />,
      },
      { path: 'upload-documents', element: <RegistrationUploadDocuments /> },
      { path: 'review-sign-contract', element: <RegistrationReviewSignContract /> },
    ],
  },
  {
    path: '/',
    element: <RootLayout />,
    loader: authLoader,
    children: [
      {
        path: '',
        element: <DashboardPage />,
      },
      {
        path: 'contracts',
        element: <ContractsPage />,
      },
      {
        path: 'community',
        children: [
          { path: '', element: <CommunityPage /> },
          { path: 'members', element: <CommunityMembersPage /> },
        ],
      },
      {
        path: 'documents',
        element: <DocumentsPage />,
      },
      {
        path: 'settings',
        element: <SettingsPage />,
      },
      {
        path: 'profile',
        element: <ProfilePage />,
        children: [
          {
            path: 'member',
            element: <MemberTabContent />,
            children: [
              {
                path: 'select-member-type',
                element: <RegistrationMemberType />,
              },
              {
                path: 'choose-your-role',
                element: <RegistrationChooseRole />,
              },
              {
                path: 'your-details',
                element: <RegistrationMemberDetails />,
              },
              {
                path: 'energy-information',
                element: <RegistrationEnergyInformation />,
              },
              {
                path: 'location-information',
                element: <RegistrationLocationInformation mode="profile" />,
              },
              {
                path: 'financial-information',
                element: <RegistrationFinancialInformation />,
              },
              {
                path: 'upload-documents',
                element: <RegistrationUploadDocuments />,
              },
              {
                path: 'review-sign-contract',
                element: <RegistrationReviewSignContract />,
              },
            ],
          },
          { path: 'admin', element: <AdminTabContent /> },
        ],
      },
    ],
  },
]);

const Posthog = ({ children }: { children: React.ReactNode }) => {
  if (process.env.NODE_ENV === 'production') {
    return (
      <PostHogProvider apiKey={import.meta.env.VITE_POSTHOG_KEY} options={options}>
        {children}
      </PostHogProvider>
    );
  }
  return <>{children}</>;
};

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary>
      <UserSupport />
      <Posthog>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <RouterProvider router={router} />
            <Toaster />
          </AuthProvider>
        </QueryClientProvider>
      </Posthog>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
);
