import { useTranslation } from 'react-i18next';

import { Title } from '../ui/custom/title';
import { Loader } from '../ui/loader';
import { DocumentUploader } from './DocumentUploader';
import { useUserDocuments } from './hooks/useUserDocuments';
import { DocumentName } from './types/documents';
import { UserDocument } from './UserDocument';

export const DocumentsList = () => {
  const { t } = useTranslation();
  const { documents, isLoading } = useUserDocuments();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <Title title={t('documents.title')} />
      <div className="mt-4 grid gap-4">
        {Object.entries(documents).map(([filename, document]) => (
          <div
            className="rounded-lg border bg-white p-6 flex flex-col gap-6"
            key={filename}
          >
            <div className="flex flex-col gap-2">
              <h2 className="text-lg font-semibold">
                {t(`documents.list.${filename}.title`)}
              </h2>
              <p className="text-sm text-primary-light font-light">
                {t(`documents.list.${filename}.description`)}
              </p>
            </div>
            {document ? (
              <UserDocument document={document} />
            ) : (
              <DocumentUploader filename={filename as DocumentName} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
