import { useTranslation } from 'react-i18next';

import { cn } from '@/lib/utils';

import { useAuth } from '../auth/AuthProvider';
import { useUserCommunity } from '../community/hooks/useUserCommunity';
import { Button } from '../ui/button';
import { Title } from '../ui/custom/title';
import { Form, FormControl, FormField, FormItem, FormMessage } from '../ui/form';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import { Loader } from '../ui/loader';
import { useAdminForm } from './hooks/useAdminForm';

export const AdminTabContent = () => {
  const { member, isLoading } = useUserCommunity();

  if (isLoading || !member) {
    return null;
  }

  const form = useAdminForm(member);
  const { session } = useAuth();
  const { t } = useTranslation();

  return (
    <>
      <Title className="p-0 mb-5" subTitle={t('admin.title')} />
      <Form {...form}>
        <form className="max-w-[500px]" onSubmit={form.onSubmit}>
          <FormItem className="mb-4">
            <Label>{t('community.members.form.email')}</Label>
            <FormControl>
              <Input id="email" type="text" value={session?.user.email} disabled />
            </FormControl>
            <FormMessage />
          </FormItem>
          <FormField
            name="first_name"
            control={form.control}
            render={({ field }) => (
              <FormItem className="mb-4">
                <Label>{t('community.members.form.firstName')}</Label>
                <FormControl>
                  <Input
                    {...form.register('first_name', { required: true })}
                    {...field}
                    placeholder={t('community.members.form.firstNamePlaceholder')}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name="last_name"
            control={form.control}
            render={({ field }) => (
              <FormItem className="mb-4">
                <Label>{t('community.members.form.lastName')}</Label>
                <FormControl>
                  <Input
                    {...form.register('last_name', { required: true })}
                    {...field}
                    placeholder={t('community.members.form.lastNamePlaceholder')}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name="phone_number"
            //  TODO: use formatted phone input here
            control={form.control}
            render={({ field }) => (
              <FormItem className="mb-4">
                <Label>{t('community.members.form.phoneNumber')}</Label>
                <FormControl>
                  <Input
                    {...form.register('phone_number', { required: true })}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="mt-8 flex">
            <Button
              type="submit"
              className={cn({ 'cursor-progress': form.isPending })}
              disabled={form.isPending}
            >
              {form.isPending && <Loader className="mr-1" />}
              {t('community.members.form.save')}
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
};
