import { createClient, SupabaseClientOptions } from '@supabase/supabase-js';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

export const createSupabaseClient = (config?: SupabaseClientOptions<'public'>) => {
  return createClient(supabaseUrl, supabaseAnonKey, config);
};

export const supabase = createSupabaseClient();
