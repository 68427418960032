import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useUserCommunity } from '../community/hooks/useUserCommunity';
import { FinancialInformationForm } from '../member/FinancialInformationForm';
import { Title } from '../ui/custom/title';

type Props = {
  mode?: 'registration' | 'profile';
};

export const RegistrationFinancialInformation = ({ mode = 'registration' }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { member, isLoading } = useUserCommunity();

  if (!member || isLoading) {
    return null;
  }

  return (
    <FinancialInformationForm
      member={member}
      title={<Title title={t('registration.financialInformation.title')} />}
      submitText={
        mode === 'registration'
          ? t('registration.form.next')
          : t('registration.form.save')
      }
      onSuccess={
        mode === 'registration' ? () => navigate('../review-sign-contract') : undefined
      }
      cancel={{
        text: t('registration.form.back'),
        onClick: () => navigate('../location-information'),
      }}
    />
  );
};
