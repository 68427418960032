import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';

import { useUserCommunity } from '@/components/community/hooks/useUserCommunity';
import { Loader } from '@/components/ui/loader';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';

export const ProfilePage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { member, isLoading } = useUserCommunity();

  if (!member || isLoading) {
    return <Loader />;
  }

  if (!member.is_admin) {
    return <Outlet />;
  }

  return (
    <Tabs defaultValue="adminDetails">
      <TabsList className="grid grid-cols-2 w-fit mb-4">
        <TabsTrigger onClick={() => navigate('admin')} value="adminDetails">
          {t('admin.tabs.adminProfile')}
        </TabsTrigger>
        <TabsTrigger
          onClick={() => navigate('member/select-member-type')}
          value="memberDetails"
        >
          {t('admin.tabs.memberProfile')}
        </TabsTrigger>
      </TabsList>

      <TabsContent value="adminDetails">
        <Outlet />
      </TabsContent>
      <TabsContent value="memberDetails">
        <Outlet />
      </TabsContent>
    </Tabs>
  );
};
