import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

export const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    if (i18n.language.includes('en')) {
      i18n.changeLanguage('en');
    } else {
      i18n.changeLanguage('it');
    }
  }, [i18n.language]);

  return (
    <Select
      defaultValue={i18n.language}
      onValueChange={(value) => i18n.changeLanguage(value)}
    >
      <SelectTrigger>
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectItem value="en">English</SelectItem>
          <SelectItem value="it">Italiano</SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};
