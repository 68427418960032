import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import { z } from 'zod';

import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { fetchWithSupabase } from '@/lib/api/fetch';
import { userCommunitiesQueryKey } from '@/lib/api/queryKeys';
import { CreateCommunityResponse } from '@/lib/api/types';
import { CREATE_COMMUNITY_URL } from '@/lib/api/url';

import { Checkbox } from '../ui/checkbox';
import { Label } from '../ui/label';

type Props = {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
};

const communityCreationFormSchema = z.object({
  name: z.string({
    required_error: 'Community name is required.',
  }),
});

type FormValues = z.infer<typeof communityCreationFormSchema>;

export const CommunityCreationModal = ({ isOpen, onOpenChange }: Props) => {
  const { t } = useTranslation();
  const [isPrerequisitesChecked, setIsPrerequisitesChecked] = useState(false);
  const queryClient = useQueryClient();
  const form = useForm<FormValues>({
    resolver: zodResolver(communityCreationFormSchema),
    mode: 'onSubmit',
    defaultValues: {
      name: '',
    },
  });

  const { mutate } = useMutation({
    mutationKey: ['community-create'],
    mutationFn: async (values: FormValues) => {
      return await fetchWithSupabase<CreateCommunityResponse>(CREATE_COMMUNITY_URL, {
        method: 'POST',
        body: JSON.stringify({
          name: values.name,
        }),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [userCommunitiesQueryKey] });
      onOpenChange(false);
      toast.success(t('community.create.success'));
    },
    onError: () => {
      toast.error(t('community.create.error'));
    },
  });

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[625px]">
        <DialogHeader>
          <DialogTitle>{t('community.create.title')}</DialogTitle>
          <DialogDescription>{t('community.create.description')}</DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit((values) => mutate(values))}
            className="space-y-4"
          >
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem className="mb-6">
                  <Label>{t('community.create.name')}</Label>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="items-top flex space-x-2">
              <Checkbox
                id="terms1"
                onCheckedChange={(checked) => setIsPrerequisitesChecked(Boolean(checked))}
              />
              <div className="grid gap-1.5 leading-none">
                <label
                  htmlFor="terms1"
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  Accetta termini e condizioni
                </label>
                <p className="text-sm text-muted-foreground">
                  <Trans i18nKey="login.termsAndPrivacy">
                    By clicking continue, you agree to our
                    <a
                      href="https://www.energico.io/prerequisiti-gse"
                      target="_blank"
                      rel="noreferrer"
                      className="underline underline-offset-4 hover:text-primary"
                    >
                      Terms of Service
                    </a>
                    .
                  </Trans>
                </p>
              </div>
            </div>
            <div className="flex justify-end">
              <Button disabled={!isPrerequisitesChecked} type="submit">
                {t('community.create.createCta')}
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
