import { useQuery } from '@tanstack/react-query';

export const AirPollutionWidget = () => {
  const { data, isFetching, error } = useQuery({
    queryKey: ['airPollution'],
    queryFn: async () => {
      const res = await fetch(
        `https://api.openweathermap.org/data/2.5/air_pollution?lat=45.6667&lon=11.45&appid=fafe580268d0f6c6273824cd0f1fc088&units=metric&lang=it`,
      );
      if (!res.ok) {
        throw new Error('Network response was not ok');
      }
      return res.json();
    },
  });

  if (isFetching) {
    return <p>Caricamento dei dati sulla qualità dell&apos;aria...</p>;
  }

  if (error) {
    return <p>Errore nel recupero dei dati sulla qualità dell&apos;aria.</p>;
  }

  if (data && data.list && data.list.length > 0) {
    const airQuality = data.list[0];
    return (
      <div className="flex flex-col items-center">
        <div className="mt-2">
          <p className="text-sm">
            Indice di Qualità dell&apos;Aria (AQI):{' '}
            <b>{getAQIDescription(airQuality.main.aqi)}</b>
          </p>
          <p className="text-sm">PM2.5: {airQuality.components.pm2_5} µg/m³</p>
          <p className="text-sm">PM10: {airQuality.components.pm10} µg/m³</p>
          <p className="text-sm">O₃: {airQuality.components.o3} µg/m³</p>
          <p className="text-sm">NO₂: {airQuality.components.no2} µg/m³</p>
        </div>
      </div>
    );
  } else {
    return <p>Nessun dato disponibile sulla qualità dell&apos;aria.</p>;
  }
};

const getAQIDescription = (aqi: number) => {
  switch (aqi) {
    case 1:
      return 'Buona';
    case 2:
      return 'Discreta';
    case 3:
      return 'Moderata';
    case 4:
      return 'Scarsa';
    case 5:
      return 'Molto scarsa';
    default:
      return 'N/A';
  }
};
