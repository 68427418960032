import { Session } from '@supabase/supabase-js';
import constate from 'constate';
import { useEffect, useState } from 'react';

import { supabase } from '../../lib/supabase/config';

export const useAuthInternal = () => {
  const [session, setSession] = useState<Session | null>(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
  }, []);

  return { session };
};

export const [AuthProvider, useAuth] = constate(useAuthInternal);
