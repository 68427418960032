import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  hasCommunityInfoFilled,
  useUserCommunity,
} from '@/components/community/hooks/useUserCommunity';
import { useUserDocuments } from '@/components/documents/hooks/useUserDocuments';
import { Button } from '@/components/ui/button';
import { Card, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Callout } from '@/components/ui/custom/callout';
import { Title } from '@/components/ui/custom/title';
import { fetchWithSupabase } from '@/lib/api/fetch';
import { communityMembersQueryKey } from '@/lib/api/queryKeys';
import { CommunityMembersResponse } from '@/lib/api/types';
import { GET_COMMUNITY_MEMBERS_URL } from '@/lib/api/url';

import { AirPollutionWidget } from './AirPollutionWidget';
import { Graph } from './Graph';
import { WeatherWidget } from './WeatherWidget';

export const DashboardPage = () => {
  const { community, member } = useUserCommunity();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { areAllDocumentsUploaded } = useUserDocuments();

  const { data } = useQuery({
    queryKey: [communityMembersQueryKey, community?.id],
    initialData: { success: false, members: [] },
    queryFn: async () => {
      return await fetchWithSupabase<CommunityMembersResponse>(
        GET_COMMUNITY_MEMBERS_URL(community?.id ?? ''),
        {
          method: 'GET',
        },
      );
    },
  });

  return (
    <div className="flex min-h-screen flex-col space-y-4 sm:p-2">
      <Title
        title={t('dashboard.title')}
        subTitle="Benvenuto nella tua dashboard della comunità!"
      />

      {/* Top row - Community Status & Daily Tip */}
      <div className="flex flex-col gap-4 md:flex-row">
        {/* Community Status Card */}
        <Card className="w-full md:w-5/12">
          <CardHeader>
            <CardTitle>{t('dashboard.cardTitle', { name: community?.name })}</CardTitle>
            <CardDescription className="pt-3 text-base md:text-lg leading-relaxed">
              {t('dashboard.status', {
                members: data.members.length,
              })}
            </CardDescription>
            <div className="flex flex-col gap-4">
              {((member?.is_admin && member?.member_role === 'PRODUCER') ||
                member?.member_role === 'PROSUMER') &&
                !areAllDocumentsUploaded && (
                  <Callout
                    className="w-full"
                    text={t('dashboard.documentsMissingNotification')}
                    cta={
                      <Button onClick={() => navigate('/documents')}>
                        {t('dashboard.documentsCta')}
                      </Button>
                    }
                  />
                )}
              {member?.is_admin && community && !hasCommunityInfoFilled(community) && (
                <Callout
                  className="w-full"
                  text={t('dashboard.communityInfoMissingNotification')}
                  cta={
                    <Button onClick={() => navigate('/community')}>
                      {t('dashboard.communityInfoCta')}
                    </Button>
                  }
                />
              )}
            </div>
          </CardHeader>
        </Card>

        {/* Daily Tip Card */}
        <Card className="w-full md:w-7/12">
          <CardHeader>
            <CardTitle>Consiglio del Giorno</CardTitle>
            <CardDescription className="pt-3 text-sm md:text-base leading-relaxed">
              <b>Mantieni gli impianti efficienti:</b> Fai regolarmente la manutenzione
              degli impianti di riscaldamento e condizionamento per garantirne il corretto
              funzionamento e prevenire sprechi energetici. Una pulizia periodica dei
              filtri e degli scambiatori di calore può migliorare l&apos;efficienza
              energetica del tuo impianto.
            </CardDescription>
          </CardHeader>
        </Card>
      </div>

      {/* Bottom row - Weather, Air Quality & Graph */}
      <div className="flex flex-col gap-4 lg:flex-row">
        {/* Weather & Air Quality Column */}
        <div className="flex flex-col sm:flex-row gap-4 md:gap-6 lg:flex-col lg:w-3/12">
          <Card className="flex-1">
            <CardHeader>
              <CardTitle>Meteo</CardTitle>
              <CardDescription className="pt-3 text-base md:text-lg leading-relaxed">
                <WeatherWidget />
              </CardDescription>
            </CardHeader>
          </Card>
          <Card className="flex-1">
            <CardHeader>
              <CardTitle>Qualità dell&apos;Aria</CardTitle>
              <CardDescription className="text-base md:text-lg">
                <AirPollutionWidget />
              </CardDescription>
            </CardHeader>
          </Card>
        </div>

        {/* Graph Card */}
        <Card className="h-[400px] w-full lg:w-9/12 pb-6">
          <CardTitle className="ml-4 mt-4 md:ml-6 md:mt-6 -mb-10 text-lg md:text-xl">
            L&apos;energia solare non è mai stata così conveniente
          </CardTitle>
          <Graph />
        </Card>
      </div>
    </div>
  );
};
