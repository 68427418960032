import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { fetchWithSupabase } from '@/lib/api/fetch';
import { JoinInvitationResponse } from '@/lib/api/types';
import { JOIN_INVITATION } from '@/lib/api/url';

import { useAuth } from '../auth/AuthProvider';
import { Button } from '../ui/button';
import { Callout } from '../ui/custom/callout';
import { Loader } from '../ui/loader';

type JoinCommunityProps = {
  code: string;
};

export const JoinCommunity = ({ code }: JoinCommunityProps) => {
  const { t } = useTranslation();
  const { session } = useAuth();
  const navigate = useNavigate();
  const { isLoading } = useQuery({
    queryKey: ['accept-invitation', code],
    queryFn: async () => {
      return fetchWithSupabase<JoinInvitationResponse>(JOIN_INVITATION(code), {
        method: 'POST',
        body: JSON.stringify({
          email: session?.user.email,
        }),
      });
    },
    enabled: !!session,
    retry: 2,
  });

  if (isLoading) {
    return (
      <div className="flex h-screen w-full flex-col items-center justify-center gap-2">
        <Loader />
        <p>{t('community.invitations.join.loading')}</p>
      </div>
    );
  }

  return (
    <div className="flex h-screen w-full flex-col items-center justify-center gap-4">
      <div className="flex flex-col gap-4 items-center">
        <Callout text={t('community.invitations.join.success')} />

        <Button
          onClick={() => {
            navigate('/');
          }}
          className="w-fit justify-start mt-auto"
        >
          {t('community.invitations.join.enter')}
        </Button>
      </div>
    </div>
  );
};
