import { DocusealForm } from '@docuseal/react';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../auth/AuthProvider';
import { useUserContracts } from '../documents/hooks/useUserContracts';
import { Button } from '../ui/button';
import { Title } from '../ui/custom/title';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../ui/dialog';
import { Loader } from '../ui/loader';

export const RegistrationReviewSignContract = () => {
  const { isLoading, contracts } = useUserContracts();
  const { session } = useAuth();
  const { i18n, t } = useTranslation();
  const queryClient = useQueryClient();
  const [selectedContract, setSelectedContract] = useState<{
    contract_slug: string;
    template_id: number;
    is_signed: boolean;
    contract_name: string;
  } | null>(null);

  if (isLoading) {
    return (
      <div className="flex h-screen w-full flex-col items-center justify-center gap-2">
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <Title title="Contracts" />
      <div className="mt-4 grid gap-4">
        {contracts.map(({ contract_name, contract_slug, is_signed, template_id }) => (
          <div
            className="rounded-lg border bg-white p-6 flex flex-col gap-6"
            key={contract_slug}
          >
            <div className="flex flex-col gap-2">
              <h2 className="text-lg font-semibold">{contract_name}</h2>
              <Button
                className="w-fit"
                onClick={() =>
                  setSelectedContract({
                    contract_slug,
                    template_id,
                    is_signed,
                    contract_name,
                  })
                }
              >
                {is_signed
                  ? t('registration.reviewAndSignContract.viewContract')
                  : t('registration.reviewAndSignContract.signContract')}
              </Button>
            </div>
          </div>
        ))}
      </div>
      <Dialog
        open={selectedContract !== null}
        onOpenChange={() => setSelectedContract(null)}
      >
        <DialogContent className="sm:max-w-[800px]">
          <DialogHeader>
            <DialogTitle>{selectedContract?.contract_name}</DialogTitle>
          </DialogHeader>
          <div className="overflow-y-auto max-h-[90vh] border rounded-md">
            <DocusealForm
              src={`https://docuseal.co/d/${selectedContract?.contract_slug}`}
              email={session?.user.email}
              language={i18n.language}
              expand={false}
              withTitle={false}
              onComplete={() => {
                setSelectedContract(null);
                queryClient.invalidateQueries({ queryKey: ['contracts'] });
              }}
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
