import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useUserCommunity } from '@/components/community/hooks/useUserCommunity';
import { Member } from '@/components/community/types/member';
import { fetchWithSupabase } from '@/lib/api/fetch';
import { documentsQueryKey } from '@/lib/api/queryKeys';
import { DocumentsResponse } from '@/lib/api/types';
import { GET_DOCUMENTS_URL } from '@/lib/api/url';

import { UserDocument } from '../types/documents';

type DocumentsMap = Record<string, UserDocument | undefined>;

export const useUserDocuments = () => {
  const { member } = useUserCommunity();
  const { data, isLoading } = useQuery({
    queryKey: [documentsQueryKey],
    queryFn: async () => await fetchWithSupabase<DocumentsResponse>(GET_DOCUMENTS_URL),
  });

  if (!member) {
    // TODO: loading state
    return {
      documents: {},
      isLoading: true,
      areAllDocumentsUploaded: false,
    };
  }
  const documentsListMap = getDocumentsListMap(member);

  const documents = useMemo<DocumentsMap>(() => {
    if (!data) {
      return documentsListMap;
    }

    return data.documents.reduce((acc, document) => {
      if (document.is_uploaded) {
        acc[document.document_type] = document;
      }
      return acc;
    }, documentsListMap);
  }, [data]);

  const areAllDocumentsUploaded = useMemo(() => {
    return Object.values(documents).every((document) => document !== undefined);
  }, [documents]);

  return { documents, isLoading, areAllDocumentsUploaded };
};

const getDocumentsListMap = (member: Member): DocumentsMap => {
  let list: DocumentsMap = { ['IDENTITY_CARD']: undefined };

  // For every member with POD power above 3kW we need to add last 12 months energy bill attached in the documents section
  if (member.system_power && parseInt(member.system_power) > 3) {
    list = {
      ...list,
      ['LAST_YEAR_ENERGY_BILL']: undefined,
    };
  }

  if (member.type === 'PRIVATE_COMPANY') {
    list = {
      ...list,
      ['MEMORANDUM_OF_ASSOCIATION']: undefined,
      ['CERTIFICATE_OF_INCORPORATION']: undefined,
    };
  }

  if (member.member_role === 'PRODUCER' || member.member_role === 'PROSUMER') {
    list = {
      ...list,
      ['ELECTRONIC_WORKSHOP_LICENSE_CUSTOMS_CODE']: undefined,
      ['OPERATION_REGULATION']: undefined,
      ['SINGLE_LINE_DIAGRAM']: undefined,
      ['METER_ACTIVATION_REPORTS']: undefined,
      ['PHOTOVOLTAIC_MODULE_INVERTER_PHOTOS']: undefined,
      ['MODULE_SERIAL_NUMBERS']: undefined,
      ['DNSH_CHECKLIST']: undefined,
      ['GRANT_AWARD_DECISION']: undefined,
    };
  }

  return list;
};
