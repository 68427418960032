import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';

import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Loader } from '@/components/ui/loader';
import { fetchWithSupabase } from '@/lib/api/fetch';
import { JOIN_INVITATION } from '@/lib/api/url';
import { supabase } from '@/lib/supabase/config';

export const Register = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const invitationCode = searchParams.get('invitationCode');
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      email: email ?? '',
      password: '',
    },
  });

  const acceptInvitation = useMutation({
    mutationFn: async (code: string) => {
      return await fetchWithSupabase(JOIN_INVITATION(code), {
        method: 'POST',
        body: JSON.stringify({
          email,
        }),
      });
    },
    onError: (error) => {
      toast.error(t('register.error'), {
        description: error.message,
      });
    },
    onSuccess: () => {
      navigate('/');
    },
  });

  const handleRegister = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    // Proceed with registration
    const { error: signUpError } = await supabase.auth.signUp({
      email,
      password,
    });
    if (signUpError) {
      toast.error(t('register.error'), {
        description: signUpError.message,
      });
      return;
    }

    // Log the user in
    const { error: signInError } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (signInError) {
      toast.error(t('register.error'), {
        description: signInError.message,
      });
      return;
    }

    if (invitationCode) {
      acceptInvitation.mutate(invitationCode);
      return;
    }
    navigate('/');
  };

  return (
    <div className="container relative h-screen flex-col items-center justify-center grid lg:max-w-[840px] mx-auto lg:grid-cols-2 lg:px-0 overflow-auto">
      <div className="relative hidden bg-background h-full p-10 lg:flex items-center justify-center flex-col">
        <div>
          <img alt="logo" src="/energico.png" className="h-16" />
          <blockquote className="space-y-2">
            <p className="text-md">{t('register.punchline')}</p>
          </blockquote>
        </div>
      </div>
      <div className="lg:p-8">
        <div className="mx-auto flex w-full flex-col justify-center space-y-6 max-w-[350px]">
          <div className="flex flex-col space-y-2 text-center">
            <h1 className="text-2xl font-semibold tracking-tight">
              {t('register.title')}
            </h1>
            <p className="text-sm text-muted-foreground">{t('register.subtitle')}</p>
          </div>
          <form onSubmit={handleSubmit(handleRegister)}>
            <div className="grid gap-2">
              <div className="grid gap-1">
                <Label className="sr-only" htmlFor="email">
                  {t('register.email')}
                </Label>
                <Input
                  id="email"
                  placeholder={t('register.emailPlaceholder')}
                  type="email"
                  disabled={Boolean(email)}
                  autoCapitalize="none"
                  autoComplete="email"
                  autoCorrect="off"
                  {...register('email', {
                    required: true,
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: t('register.emailInvalid'),
                    },
                  })}
                />
                <Label className="sr-only" htmlFor="password">
                  {t('register.password')}
                </Label>
                <Input
                  id="password"
                  placeholder={t('register.passwordPlaceholder')}
                  type="password"
                  autoCapitalize="none"
                  autoComplete="new-password"
                  autoCorrect="off"
                  {...register('password', {
                    required: t('register.passwordRequired'),
                  })}
                />
              </div>
              <Button disabled={isSubmitting}>
                {isSubmitting && <Loader className="mr-1" />}
                {t('register.cta')}
              </Button>
            </div>
          </form>
          <p className="px-8 text-center text-sm text-muted-foreground">
            {t('register.hasAccount')}{' '}
            <Link to="/login" className="underline underline-offset-4 hover:text-primary">
              {t('register.link')}
            </Link>
          </p>
          <p className="px-8 text-center text-sm text-muted-foreground">
            <Trans i18nKey="register.termsAndPrivacy">
              By clicking continue, you agree to our
              <a
                href="https://www.energico.io/terms-and-conditions-app"
                target="_blank"
                rel="noreferrer"
                className="underline underline-offset-4 hover:text-primary"
              >
                Terms of Service
              </a>
              .
            </Trans>
          </p>
        </div>
      </div>
    </div>
  );
};
