import { MessageCircleQuestionIcon } from 'lucide-react';

export const UserSupport = () => {
  return (
    <div className="fixed z-50 right-2 bottom-2">
      <a
        href="mailto:info@energico.io?subject=Richiesta di Supporto&body=Ciao,%0D%0A%0D%0AHo bisogno di aiuto con..."
        className="w-14 h-14 rounded-full bg-primary flex items-center justify-center cursor-pointer"
      >
        <MessageCircleQuestionIcon size="24" color="white" />
      </a>
    </div>
  );
};
