import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useUserCommunity } from '../community/hooks/useUserCommunity';
import { Member } from '../community/types/member';
import { EnergyInformationForm } from '../member/EnergyInformationForm';
import { Title } from '../ui/custom/title';

export const RegistrationEnergyInformation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { member } = useUserCommunity();

  if (!member) {
    return null;
  }

  return (
    <EnergyInformationForm
      member={member}
      title={<Title {...getTitles(member, t)} />}
      submitText={t('registration.form.next')}
      onSuccess={() => navigate('../location-information')}
      cancel={{
        text: t('registration.form.back'),
        onClick: () => navigate('../your-details'),
      }}
    />
  );
};

const getTitles = (member: Member, t: (key: string) => string) => {
  if (member.member_role === 'CONSUMER') {
    return {
      title: t('registration.energyInformationConsumer.title'),
    };
  }

  // producer / prosumer
  return {
    title: t('registration.energyInformationProducer.title'),
  };
};
