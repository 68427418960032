import { CaretSortIcon, DotsHorizontalIcon } from '@radix-ui/react-icons';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table';
import { UserRoundPlusIcon } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { fetchWithSupabase } from '@/lib/api/fetch';
import { communityMembersQueryKey } from '@/lib/api/queryKeys';
import { CommunityMembersResponse } from '@/lib/api/types';
import { GET_COMMUNITY_MEMBERS_URL, GET_MEMBER_URL } from '@/lib/api/url';

import { Badge } from '../ui/badge';
import { Loader } from '../ui/loader';
import { InviteMembersModal } from './InviteMembersModal';
import { Member } from './types/member';

type Props = {
  communityId: string;
};

export const CommunityMembersList = ({ communityId }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [isInviteMembersModalOpen, setIsInviteMembersModalOpen] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const { data, isFetching, isError } = useQuery({
    queryKey: [communityMembersQueryKey, communityId],
    initialData: { success: false, members: [] },
    queryFn: async () => {
      return await fetchWithSupabase<CommunityMembersResponse>(
        GET_COMMUNITY_MEMBERS_URL(communityId),
        {
          method: 'GET',
        },
      );
    },
  });

  const columns: ColumnDef<Member>[] = [
    {
      accessorKey: 'last_name',
      header: t('community.members.last_name'),
      cell: ({ row }) => <div className="min-w-[100px]">{row.getValue('last_name')}</div>,
    },
    {
      accessorKey: 'first_name',
      header: t('community.members.first_name'),
      cell: ({ row }) => (
        <div className="min-w-[100px]">{row.getValue('first_name')}</div>
      ),
    },
    {
      accessorKey: 'is_admin',
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
            className="hidden md:flex items-center"
          >
            {t('community.members.role')}
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        );
      },
      cell: ({ row }) => (
        <div className="min-w-[80px]">
          {row.getValue('is_admin') ? (
            <Badge variant="secondary">{t('community.members.admin')}</Badge>
          ) : (
            <Badge variant="secondary">{t('community.members.member')}</Badge>
          )}
        </div>
      ),
    },
    {
      id: 'actions',
      enableHiding: false,
      cell: ({ row }) => {
        return (
          <div className="text-right">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <DotsHorizontalIcon className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem
                  onClick={async () => {
                    const id: string = row.getValue('id');
                    await queryClient.fetchQuery<Member>({
                      queryKey: ['member', id],
                      queryFn: async () => {
                        return await fetchWithSupabase(GET_MEMBER_URL(id), {
                          method: 'GET',
                        });
                      },
                    });
                  }}
                >
                  {t('community.viewMemberDetails')}
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      },
    },
  ];

  const table = useReactTable({
    data: data.members,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  if (isFetching) {
    return <Loader />;
  }

  if (isError) {
    return null;
  }

  return (
    <>
      <div className="w-full">
        <div className="flex justify-end py-4 px-2">
          <Button
            className="flex items-center gap-2 w-full md:w-auto"
            onClick={() => setIsInviteMembersModalOpen(true)}
          >
            <UserRoundPlusIcon /> {t('community.inviteMembers')}
          </Button>
        </div>
        <div className="rounded-md border overflow-x-auto">
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id} className="whitespace-nowrap">
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id} className="whitespace-nowrap">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} className="h-24 text-center">
                    {t('community.members.empty')}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <div className="flex justify-end gap-2 py-4 px-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            className="flex-1 md:flex-none"
          >
            {t('community.members.previous')}
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            className="flex-1 md:flex-none"
          >
            {t('community.members.next')}
          </Button>
        </div>
      </div>
      <InviteMembersModal
        communityId={communityId}
        open={isInviteMembersModalOpen}
        onOpenChange={() => setIsInviteMembersModalOpen(false)}
      />
    </>
  );
};
