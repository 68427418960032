import { FC } from 'react';

import { Sidebar } from '@/components/common/Sidebar';

interface SuperAdminLayoutProps {
  children?: React.ReactNode;
}
export const SuperAdminLayout: FC<SuperAdminLayoutProps> = ({ children }) => {
  return (
    <div className="pt-16 md:pt-0 h-screen flex bg-[#fffdfa]">
      <Sidebar superadmin />
      <main className="flex-1 p-6">{children}</main>
    </div>
  );
};
