import { Trans } from 'react-i18next';

import { LogoutButton } from '../auth/LogoutButton';
import { useUserCommunity } from './hooks/useUserCommunity';

export const CommunityPendingApproval = () => {
  const { community } = useUserCommunity();

  return (
    <div className="flex h-screen px-6 md:px-0 w-full flex-col gap-4 items-center justify-center ">
      <div className="flex flex-col gap-4 items-center text-center">
        <img alt="logo" src="/energico.png" className="h-16" />
      </div>
      <div className="text-sm text-muted-foreground max-w-[730px] flex flex-col gap-2 mt-6">
        <Trans
          i18nKey="community.inReview"
          components={{
            1: <span />,
            2: <b />,
            3: <span />,
            4: (
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a href="mailto:support@energico.com" className="text-primary underline" />
            ),
          }}
          values={{ communityName: community?.name }}
        />
      </div>

      <LogoutButton />
    </div>
  );
};
