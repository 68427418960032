import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useUserCommunity } from '../community/hooks/useUserCommunity';
import { MemberRoleForm } from '../member/MemberRoleForm';
import { Title } from '../ui/custom/title';

export const RegistrationChooseRole = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { member, isLoading } = useUserCommunity();

  if (isLoading || !member) {
    return null;
  }

  return (
    <MemberRoleForm
      member={member}
      title={
        <Title
          title={t('registration.chooseYourRole.title')}
          subTitle={t('registration.chooseYourRole.subtitle')}
        />
      }
      submitText={t('registration.form.next')}
      onSuccess={() => navigate('../your-details')}
      cancel={{
        text: t('registration.form.back'),
        onClick: () => navigate('../select-member-type'),
      }}
    />
  );
};
