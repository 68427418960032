import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useUserCommunity } from '../community/hooks/useUserCommunity';
import { MemberTypeForm } from '../member/MemberTypeForm';
import { Title } from '../ui/custom/title';

export const RegistrationMemberType = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { member } = useUserCommunity();

  if (!member) {
    return null;
  }

  return (
    <MemberTypeForm
      member={member}
      title={
        <Title
          title={t('registration.selectMemberType.title')}
          subTitle={t('registration.selectMemberType.subtitle')}
        />
      }
      submitText={t('registration.form.next')}
      onSuccess={() => navigate('../choose-your-role')}
    />
  );
};
