import { supabase } from '../supabase/config';

const energicoApiUrl = import.meta.env.VITE_ENERGICO_API_URL;

export async function fetchWithSupabase<T>(input: RequestInfo, init: RequestInit = {}) {
  const supabaseUser = await supabase.auth.getUser();

  const initWithSupabase = {
    headers: {
      'x-user-id': supabaseUser.data.user?.id || '',
      'content-type': 'application/json',
      ...init.headers,
    },
    ...init,
  };

  const response = await fetch(`${energicoApiUrl}${input}`, initWithSupabase);
  if (!response.ok) {
    throw new Error(`Server responded with error code ${response.status}`);
  }
  return (await response.json()) as Promise<T>;
}

export async function fetchFormDataWithSupabase<T>(
  input: RequestInfo,
  init: RequestInit = {},
) {
  const supabaseUser = await supabase.auth.getUser();

  const initWithSupabase = {
    headers: {
      'x-user-id': supabaseUser.data.user?.id || '',
      ...init.headers,
    },
    ...init,
  };

  const response = await fetch(`${energicoApiUrl}${input}`, initWithSupabase);
  if (!response.ok) {
    throw new Error(`Server responded with error code ${response.status}`);
  }
  return (await response.json()) as Promise<T>;
}

export async function fetchFileWithSupabase(input: RequestInfo, init: RequestInit = {}) {
  const supabaseUser = await supabase.auth.getUser();

  const initWithSupabase = {
    headers: {
      'x-user-id': supabaseUser.data.user?.id || '',
      ...init.headers,
    },
    ...init,
  };

  const response = await fetch(`${energicoApiUrl}${input}`, initWithSupabase);
  if (!response.ok) {
    throw new Error(`Server responded with error code ${response.status}`);
  }
  return await response.blob();
}
