export const GET_COMMUNITIES_URL = 'communities';
export const UPDATE_COMMUNITY_URL = (communityId: string) => `communities/${communityId}`;
export const CREATE_COMMUNITY_URL = 'communities';
export const GET_COMMUNITY_MEMBERS_URL = (communityId: string) =>
  `communities/${communityId}/members`;
export const GET_COMMUNITY_INVITATIONS_URL = (communityId: string) =>
  `communities/${communityId}/invitations`;

export const INVITE_USERS_TO_COMMUNITY_URL = (communityId: string) =>
  `communities/${communityId}/send-invitations`;
export const GET_INVITATION_INFO = (invitationCode: string) =>
  `invitations/${invitationCode}`;
export const JOIN_INVITATION = (invitationCode: string) =>
  `invitations/accept/${invitationCode}`;
export const JOIN_COMMUNITY = (invitationCode: string) =>
  `communities/${invitationCode}/join`;

export const GET_USER_PROFILE_URL = 'user/profile';
export const UPDATE_USER_PROFILE_URL = 'user/profile';

export const GET_MEMBER_URL = (id: string) => `members/${id}`;
export const UPDATE_MEMBER_URL = (id: string) => `members/${id}`;

export const POST_CHECK_EMAIL_REGISTERED_URL = 'check-email';

export const UPLOAD_DOCUMENT_URL = 'documents/upload';
export const GET_DOCUMENTS_URL = 'documents';
export const DOWNLOAD_DOCUMENT_URL = (fileName: string) =>
  `documents/${fileName}/download`;

export const GET_CONTRACT_INFO = 'contracts';

// superadmin routes
export const IMPERSONATE_USER_URL = (id: string) => `superadmin/impersonate/${id}`;
export const GET_ALL_USERS_URL = 'superadmin/users';
export const GET_ALL_COMMUNITIES_URL = 'superadmin/communities';
export const APPROVE_COMMUNITY_URL = (communityId: string) =>
  `communities/${communityId}/approve`;
