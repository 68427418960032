import { useQuery } from '@tanstack/react-query';

import { useUserCommunity } from '@/components/community/hooks/useUserCommunity';

export const WeatherWidget = () => {
  const { member } = useUserCommunity();
  const { data, isFetching } = useQuery({
    queryKey: ['weather'],
    initialData: [],
    queryFn: async () => {
      const res = await fetch(
        `https://api.openweathermap.org/data/2.5/weather?q=${member?.address_city}&appid=fafe580268d0f6c6273824cd0f1fc088&units=metric&lang=it`,
      );
      return res.json();
    },
  });
  return (
    <div>
      {isFetching ? (
        <p>Caricamento dei dati meteo...</p>
      ) : data && data.weather ? (
        <div className="flex items-center">
          <div>
            <img
              src={`http://openweathermap.org/img/wn/${data.weather[0].icon}@2x.png`}
              alt={data.weather[0].description}
            />
          </div>
          <div>
            <p className="text-2xl font-bold">{Math.round(data.main.temp)}°C</p>
            <p className="capitalize">{data.weather[0].description}</p>
            <p className="text-sm">Umidità: {data.main.humidity}%</p>
            <p className="text-sm">Vento: {data.wind.speed} m/s</p>
          </div>
        </div>
      ) : (
        <p>Errore nel recupero dei dati meteo.</p>
      )}
    </div>
  );
};
