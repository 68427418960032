import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Loader } from 'lucide-react';
import { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import isEmail from 'validator/es/lib/isEmail';

import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { fetchWithSupabase } from '@/lib/api/fetch';
import {
  communityInvitationsQueryKey,
  communityMembersQueryKey,
} from '@/lib/api/queryKeys';
import { SendInvitationResponse } from '@/lib/api/types';
import { INVITE_USERS_TO_COMMUNITY_URL } from '@/lib/api/url';

import { Label } from '../ui/label';

type Props = { communityId: string } & Required<
  Pick<React.ComponentProps<typeof Dialog>, 'open' | 'onOpenChange'>
>;

export const InviteMembersModal = ({ communityId, open, onOpenChange }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { isDirty, isSubmitting, errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      invites: Array.from({ length: 2 }).map(() => ({
        email: '',
      })),
    },
  });
  const { fields, append } = useFieldArray({
    control: control,
    name: 'invites',
  });
  const emails = watch('invites').map((i: { email: string }) => i.email);

  useEffect(() => {
    if (emails[emails.length - 1]) {
      append(
        { email: '' },
        {
          shouldFocus: false,
        },
      );
    }
  }, [append, emails]);

  const { mutate } = useMutation({
    mutationKey: ['send-invites', communityId],
    mutationFn: async (emails: string[]) => {
      return await fetchWithSupabase<SendInvitationResponse>(
        INVITE_USERS_TO_COMMUNITY_URL(communityId),
        {
          method: 'POST',
          body: JSON.stringify({
            emails: emails.filter(Boolean),
          }),
        },
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [communityMembersQueryKey] });
      queryClient.invalidateQueries({ queryKey: [communityInvitationsQueryKey] });
      onOpenChange(false);
      toast.success(t('community.invitations.sendModal.success'));
      reset({ invites: [] });
      navigate('/community/members');
    },
    onError: () => {
      toast.error(t('community.invitations.sendModal.error'));
    },
  });

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[625px]">
        <DialogHeader>
          <DialogTitle>{t('community.invitations.sendModal.title')}</DialogTitle>
          <DialogDescription>
            {t('community.invitations.sendModal.description')}
          </DialogDescription>
        </DialogHeader>
        <form
          onSubmit={handleSubmit(
            async ({ invites }) => await mutate(invites.map((i) => i.email)),
          )}
          className="flex flex-col gap-4"
        >
          {fields.map((item, index) => {
            const error = errors.invites?.[index]?.email;
            return (
              <Label key={item.id} className="flex flex-col gap-2.5">
                <Input
                  placeholder={t('community.invitations.email')}
                  autoComplete="new-password"
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus={false}
                  {...register(`invites.${index}.email`, {
                    validate: (value) =>
                      value === '' || isEmail(value) || 'Not a valid email',
                  })}
                />
                {error && (
                  <span className="component-400 block text-red-700">
                    {error.message}
                  </span>
                )}
              </Label>
            );
          })}
          <div className="flex justify-end">
            <Button type="submit" disabled={!isDirty || isSubmitting}>
              {isSubmitting && <Loader className="mr-1" />}
              {t('community.invitations.sendModal.inviteCta')}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};
