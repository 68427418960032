import { FileCheckIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

import { fetchFileWithSupabase } from '@/lib/api/fetch';
import { DOWNLOAD_DOCUMENT_URL } from '@/lib/api/url';

import { Button } from '../ui/button';
import { UserDocument as UserDocumentType } from './types/documents';

const downloadBlob = (blob: Blob, fileName: string): void => {
  // Create a URL for the blob
  const url: string = window.URL.createObjectURL(blob);
  const a: HTMLAnchorElement = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
};

export const UserDocument = ({ document }: { document: UserDocumentType }) => {
  const { t } = useTranslation();
  const onDownload = async () => {
    const downloadPromise = fetchFileWithSupabase(
      DOWNLOAD_DOCUMENT_URL(document.document_info?.id ?? ''),
      {
        method: 'GET',
      },
    );

    toast.promise(downloadPromise, {
      loading: t('documents.download.loading'),
      success: t('documents.download.success'),
      error: t('documents.download.error'),
    });

    const blob = await downloadPromise;
    downloadBlob(blob, document.document_type);
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="flex items-center space-x-4">
        <FileCheckIcon className="h-6 w-6 text-gray-500 dark:text-gray-400" />
        <div>
          <p className="text-sm text-gray-500 dark:text-gray-400">
            {t('documents.download.uploadedOn')}{' '}
            {new Date(document.document_info?.created_at ?? '').toLocaleDateString()}
          </p>
        </div>
      </div>
      <Button onClick={onDownload} size="sm" variant="outline" className="w-fit">
        {t('documents.download.cta')}
      </Button>
    </div>
  );
};
