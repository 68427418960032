import { InfoCircledIcon } from '@radix-ui/react-icons';

import { cn } from '@/lib/utils';

type Props = {
  text: React.ReactNode;
  variant?: 'info' | 'warning' | 'danger';
  className?: string;
  cta?: React.ReactNode;
};

export const Callout = ({ text, className, cta, variant = 'info' }: Props) => {
  return (
    <div
      className={cn(
        'mx-auto sm:mx-0 rounded-lg p-3 max-w-max',
        {
          'bg-warning': variant === 'warning',
          'bg-danger': variant === 'danger',
          'bg-info': variant === 'info',
        },
        className,
      )}
    >
      <div className="flex items-center gap-2">
        <div className="flex-shrink-0">
          <InfoCircledIcon className="w-5 h-5 text-primary" />
        </div>
        <div className="text-sm text-primary">{text}</div>
      </div>
      {cta && <div className="mt-4">{cta}</div>}
    </div>
  );
};
