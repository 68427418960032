import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { CommunityForm } from '@/components/community/CommunityForm';
import { useUserCommunity } from '@/components/community/hooks/useUserCommunity';
import { Title } from '@/components/ui/custom/title';

export const CommunityPage = () => {
  const { community, member } = useUserCommunity();
  const { t } = useTranslation();

  if (!community || !member?.is_admin) {
    return <Navigate to={'/'} />;
  }

  return (
    <div>
      <Title
        className="mb-5"
        title={community.name}
        subTitle={
          member?.is_admin ? t('community.subTitle') : t('community.subTitleMember')
        }
      />
      <CommunityForm community={community} />
    </div>
  );
};
