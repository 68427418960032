import { usePlacesWidget } from 'react-google-autocomplete';
import { useTranslation } from 'react-i18next';

import { Input } from '../input';

type AddressComponent = {
  long_name: string;
  short_name: string;
  types: string[];
};

type Address = {
  address_country?: string;
  address_region?: string;
  address_city?: string;
  address_municipality?: string;
  address_zip_code?: string;
  address_route?: string;
  address_street_number?: string;
  formatted_address?: string;
};

type Props = {
  onAddressChange: (address: Address) => void;
  types?: string[];
  placeholder?: string;
  defaultValue?: Address;
};

export const AddressAutocomplete = ({
  onAddressChange,
  defaultValue,
  types,
  placeholder,
}: Props) => {
  const { i18n, t } = useTranslation();
  const { ref } = usePlacesWidget<HTMLInputElement>({
    apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    onPlaceSelected: (place) => {
      const addressComponents: AddressComponent[] = place.address_components;
      const address: Address = {
        formatted_address: place.formatted_address,
      };

      addressComponents.forEach((component) => {
        component.types.forEach((type) => {
          switch (type) {
            case 'country':
              address.address_country = component.long_name;
              break;
            case 'administrative_area_level_1':
              address.address_region = component.long_name;
              break;
            case 'administrative_area_level_2':
            case 'locality':
              address.address_city = component.long_name;
              break;
            case 'administrative_area_level_3':
            case 'postal_town':
              address.address_municipality = component.long_name;
              break;
            case 'postal_code':
              address.address_zip_code = component.long_name;
              break;
            case 'route':
              address.address_route = component.long_name;
              break;
            case 'street_number':
              address.address_street_number = component.long_name;
              break;
          }
        });
      });
      onAddressChange(address);
    },
    language: i18n.language,
    options: {
      fields: ['address_components', 'formatted_address', 'geometry', 'name'],
      language: i18n.language,
      region: i18n.language.toLocaleUpperCase(),
      types: types || ['geocode'],
    },
  });

  return (
    <Input
      ref={ref}
      placeholder={
        placeholder || t('registration.locationInformation.addressPlaceholder')
      }
      defaultValue={defaultValue?.formatted_address || defaultValue?.address_route}
    />
  );
};
