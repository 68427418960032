import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';

import { useUserCommunity } from '../community/hooks/useUserCommunity';
import { useIsMemberFinishedRegistration } from '../member/hooks/useIsMemberFinishedRegistration';
import { RegistrationNavigationTabs } from '../registration/RegistrationNavigationTabs';
import { Button } from '../ui/button';
import { Title } from '../ui/custom/title';
import { Loader } from '../ui/loader';

export const MemberTabContent = () => {
  const { member, isLoading } = useUserCommunity();
  const { isMemberFinishedRegistration } = useIsMemberFinishedRegistration();
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (isLoading || !member) {
    return <Loader />;
  }

  if (!isMemberFinishedRegistration) {
    return (
      <div>
        <Title className="max-w-[650px] pt-0  " subTitle={t('profile.member.title')} />
        <Button onClick={() => navigate('/register/select-member-type')} className="mt-5">
          {t('profile.member.button')}
        </Button>
      </div>
    );
  }

  return (
    <div>
      <RegistrationNavigationTabs mode="profile" />
      <Outlet />
    </div>
  );
};
