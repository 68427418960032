import { cn } from '@/lib/utils';

type Props = {
  title?: string;
  subTitle?: string;
  className?: string;
};

export const Title = ({ title, subTitle, className }: Props) => {
  return (
    <div className={cn('space-y-2 pt-3 max-w-[800px]', className)}>
      {title && <h1 className="text-2xl font-bold text-primary">{title}</h1>}
      {subTitle && <div className="text-primary-light font-light">{subTitle}</div>}
    </div>
  );
};
