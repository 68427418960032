import { Navigate } from 'react-router-dom';

import { CommunityEmpty } from '../community/CommunityEmpty';
import { CommunityPendingApproval } from '../community/CommunityPendingApproval';
import {
  UserCommunityProvider,
  useUserCommunity,
} from '../community/hooks/useUserCommunity';
import {
  currentStepToPath,
  useIsMemberFinishedRegistration,
} from '../member/hooks/useIsMemberFinishedRegistration';
import StopImpersonating from '../superadmin/StopImpersonating';
import { SuperAdminLayout } from '../superadmin/SuperAdminLayout';
import { SuperAdminPanel } from '../superadmin/SuperAdminPanel';
import { Card } from '../ui/card';
import { Loader } from '../ui/loader';
import { Sidebar } from './Sidebar';

type LayoutProps = {
  children: React.ReactNode;
};

const LayoutInternal = ({ children }: LayoutProps) => {
  const { community, isLoading, member } = useUserCommunity();
  const { isMemberFinishedRegistration, currentStep } = useIsMemberFinishedRegistration();

  if (isLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <Loader />
      </div>
    );
  }

  if (member?.is_superadmin) {
    return (
      <SuperAdminLayout>
        <SuperAdminPanel />
      </SuperAdminLayout>
    );
  }

  if (!community) {
    return <CommunityEmpty />;
  }

  if (!community.is_activated) {
    return <CommunityPendingApproval />;
  }

  if (!isMemberFinishedRegistration && !member?.is_admin) {
    return <Navigate to={currentStepToPath(currentStep)} />;
  }

  return (
    <div className="pt-16 md:pt-0 h-screen flex bg-[#fffdfa]">
      <Sidebar />
      <Card
        noBorderOnMobile
        className="space-y-5 py-5 overflow-auto px-2 sm:px-4 md:px-6 lg:px-10 grow m-5"
      >
        {children}
      </Card>
    </div>
  );
};

export const Layout = ({ children }: LayoutProps) => {
  return (
    <UserCommunityProvider>
      <StopImpersonating />
      <LayoutInternal>{children}</LayoutInternal>
    </UserCommunityProvider>
  );
};
