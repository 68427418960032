import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useUserCommunity } from '../community/hooks/useUserCommunity';
import { Member } from '../community/types/member';
import { MemberDetailsForm } from '../member/MemberDetailsForm';
import { Title } from '../ui/custom/title';

export const RegistrationMemberDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { member, isLoading } = useUserCommunity();

  if (isLoading || !member) {
    return null;
  }

  return (
    <MemberDetailsForm
      member={member}
      title={<Title {...getTitles(member, t)} />}
      submitText={t('registration.form.next')}
      onSuccess={() => navigate('../energy-information')}
      cancel={{
        text: t('registration.form.back'),
        onClick: () => navigate('../choose-your-role'),
      }}
    />
  );
};

const getTitles = (member: Member, t: (key: string) => string) => {
  if (member.type === 'LEGAL_PERSON') {
    return {
      title: t('registration.memberDetails.titleLegal'),
      subTitle: t('registration.memberDetails.subtitleLegal'),
    };
  }

  if (member.type === 'PRIVATE_COMPANY') {
    return {
      title: t('registration.memberDetails.titleCompany'),
      subTitle: t('registration.memberDetails.subtitleCompany'),
    };
  }

  // Default to legal person
  return {
    title: t('registration.memberDetails.titleLegal'),
    subTitle: t('registration.memberDetails.subtitleLegal'),
  };
};
